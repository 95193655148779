import Header from "../components/Header";
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';

const ClinicInformation = () => {
    const [ClinicData, setClinicData] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchClinicInfo = async () => {
            try {
                const response = await axios.post('/api/module/clinicInformation',
                    { id: location.state }
                );

                const data = response.data;

                if (response.status === 200) {
                    setClinicData(data.data);
                } else {
                    console.error('Failed to fetch module data');
                }
            } catch (error) {
                console.error('Error fetching module data:', error);
            }
        };

        fetchClinicInfo();
    }, [location.state]);

    return (
        <div>
            <Header />
            <div style={{ paddingLeft: '27%', paddingRight: '27%' }}>
                <h1>{ClinicData.DoctorName}</h1>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="mdi-location" alt="Mdi location" src="https://c.animaapp.com/U4vrOscu/img/mdi-location.svg" />
                    <p style={{ color: "#0366d6", margin: 0, marginLeft: '8px' }}>{ClinicData.Profession}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="img" alt="Mdi location" src="https://c.animaapp.com/U4vrOscu/img/mdi-location-1.svg" />
                    <p style={{ color: "#0366d6", margin: 0, marginLeft: '8px' }}>{ClinicData.ClinicState}</p>
                </div>
                <br />
                <h3 style={{ textAlign: 'right' }}>{ClinicData.ClinicName}</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="email-icon" alt="Email icon" src="https://c.animaapp.com/U4vrOscu/img/mdi-location-1.svg" />
                    <p style={{ margin: 0, marginLeft: '8px' }}>{ClinicData.ClinicAddress}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img className="email-icon" alt="Email icon" src="https://c.animaapp.com/U4vrOscu/img/mdi-location-1.svg" />
                    <a href={`mailto:${ClinicData.DoctorEmail}`} style={{ color: "#0366d6", margin: 0, marginLeft: '8px', textDecoration: 'none' }}>
                        {ClinicData.DoctorEmail}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ClinicInformation;
