import React from 'react'
import backgroundTitle from '../assets/backgroundTitle.svg'

function Title({ text, smallText }) {
  return (
    <div className="title-container">
      <img
        src={backgroundTitle}
        style={{ width: '100%', marginBottom: '20px' }}
        alt="Background Title"
      />
      <div className="big-title">{text}</div>
      <div className="big-title" style={{ top: '65%', fontSize: '12px' }}>
        {smallText}
      </div>
    </div>
  )
}

export default Title
