import React, { useState } from 'react'
import { Form, Input, Button, message, Progress } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useSelector } from 'react-redux'
import axios from 'axios'

const ChangePassword = () => {
  const userId = useSelector(state => state.user.user?.data.id)
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
    userId
  })

  console.log(formData)

  const [passwordStrength, setPasswordStrength] = useState(0) // 0: Very Weak, 1: Weak, 2: Good, 3: Strong

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await axios.post('/api/auth/change-password', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      // console.log(response)
      if (response.status === 200) {
        message.success('Password changed successfully')
        setIsLoading(false)
      } else {
        const errorData = await response.json()
        message.error(errorData.error)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
      message.error(error.response?.data.error || 'An error occurred')
    }
  }

  const calculatePasswordStrength = value => {
    // Your logic to determine password strength
    // You can use regular expressions, length checks, etc.
    // For simplicity, let's assume a basic example here
    if (value.length < 6) {
      return 0 // Very Weak
    } else if (value.length < 10) {
      return 1 // Weak
    } else if (value.match(/[a-zA-Z]+/) && value.match(/[0-9]+/)) {
      return 2 // Good
    } else {
      return 3 // Strong
    }
  }
  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
        return 'red' // Very Weak
      case 1:
        return 'orange' // Weak
      case 2:
        return 'yellow' // Good
      case 3:
        return 'green' // Strong
      default:
        return 'transparent'
    }
  }

  const getPasswordStrengthText = strength => {
    switch (strength) {
      case 0:
        return 'Very Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Good'
      case 3:
        return 'Strong'
      default:
        return ''
    }
  }

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })

    if (fieldName === 'newPassword') {
      const strength = calculatePasswordStrength(e.target.value)
      setPasswordStrength(strength)
    }
  }
  return (
    <div>
      <Header />
      <Title text="Change Password" />

      <h2
        style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}
      >
        Change Password
      </h2>
      <Form
        name="changePasswordForm"
        style={{
          margin: 'auto',
          marginTop: '50px',
          paddingLeft: '35%',
          paddingRight: '35%'
        }}
      >
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password
            className="bottom-border-input"
            placeholder="*Password"
            onChange={e => handleChange(e, 'newPassword')}
          />
          <div
            style={{
              marginTop: '8px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Progress
              strokeColor={getPasswordStrengthColor()}
              percent={(passwordStrength + 1) * 25} // Assuming 25% for each strength level
              showInfo={false}
              style={{ width: '30%', alignSelf: 'flex-end' }}
            />
            {/* Password Strength Text */}
            <div
              style={{ color: getPasswordStrengthColor(), alignSelf: 'end' }}
            >
              Strength: {getPasswordStrengthText(passwordStrength)}
            </div>
          </div>
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            }
          ]}
        >
          <Input.Password
            className="bottom-border-input"
            placeholder="*Confirm Password"
            onChange={e => handleChange(e, 'confirmPassword')}
          />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{
                background: 'transparent',
                color: '#6164c1',
                border: '2px solid #6164c1',
                width: '50%'
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Footer />
    </div>
  )
}

export default ChangePassword
