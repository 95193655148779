import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'

const ModulePreAssessment = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const moduleId = location.state
  const userId = useSelector(state => state.user.user?.data.id)
  // console.log('userId', userId)
  const [preQuestions, setPreQuestions] = useState([])
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [userResponses, setUserResponses] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const [selectedAnswers, setSelectedAnswers] = useState([])

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.post('/api/module/fetchPreQuestion', {
          moduleId
        })

        setPreQuestions(response.data.data)
      } catch (error) {
        console.error('Error fetching questions:', error)
        setPreQuestions([])
      }
    }

    fetchQuestions()
  }, [moduleId])

  const handleAnswerChange = event => {
    const answerId = event.target.value
    const isChecked = event.target.checked

    if (isChecked) {
      // Add the selected answer to the list
      setSelectedAnswers(prevSelectedAnswers => [
        ...prevSelectedAnswers,
        answerId
      ])
    } else {
      // Remove the unselected answer from the list
      setSelectedAnswers(prevSelectedAnswers =>
        prevSelectedAnswers.filter(id => id !== answerId)
      )
    }
  }

  const handleNext = () => {
    if (selectedAnswer) {
      setUserResponses(prevResponses => [
        ...prevResponses,
        {
          questionId: preQuestions[currentQuestionIndex].id,
          answerId: selectedAnswer
        }
      ])

      setCurrentQuestionIndex(prevIndex => prevIndex + 1)
      setSelectedAnswer(null)
    } else {
      // User must select an answer before proceeding
      alert('Please select an answer before proceeding.')
    }
  }

  const handleSubmit = () => {
    if (selectedAnswers.length > 0) {
      setUserResponses(prevResponses => [
        ...prevResponses,
        {
          questionId: preQuestions[currentQuestionIndex].id,
          answerIds: selectedAnswers // Use an array for multiple selected answers
        }
      ])

      setCurrentQuestionIndex(prevIndex => prevIndex + 1)
      setSelectedAnswers([]) // Clear the selected answers for the next question
    } else {
      // User must select at least one answer before proceeding
      alert('Please select at least one answer before proceeding.')
    }
  }

  const submitAnswer = () => {
    const updateAssessment = async () => {
      try {
        const response = await axios.post(
          '/api/module/updatePreAssessmentStatus',
          {
            userResponses,
            userId,
            moduleId
          }
        )
        if (response.status === 200) {
          window.location.reload() // Refresh the page
        } else {
          console.log('Failed to update assessment status')
        }
      } catch (error) {
        console.error('Error updating assessment status:', error)
      }
    }

    updateAssessment()
  }

  return (
    <div className="video">
      <div className="assessment-container">
        <div style={{ padding: '50px' }}>
          {Array.isArray(preQuestions) &&
          preQuestions.length > 0 &&
          currentQuestionIndex < preQuestions.length ? (
            <div key={preQuestions[currentQuestionIndex].id}>
              <h4>{`${currentQuestionIndex + 1}. ${
                preQuestions[currentQuestionIndex].question
              }`}</h4>
              {Array.isArray(preQuestions[currentQuestionIndex].PreAnswers) &&
                preQuestions[currentQuestionIndex].PreAnswers.map(answer => (
                  <div key={answer.id} style={{ marginBottom: '10px' }}>
                    <label>
                      <input
                        type="checkbox" // Change the input type to checkbox
                        name={`question_${preQuestions[currentQuestionIndex].id}`}
                        value={answer.id}
                        checked={selectedAnswers.includes(answer.id)}
                        onChange={handleAnswerChange}
                      />
                      {answer.answer}
                    </label>
                    <br />
                  </div>
                ))}
              <button
                style={{
                  margin: '0 auto',
                  display: 'block',
                  marginTop: '20px',
                  padding: '10px 40px'
                }}
                htmlType="button"
                onClick={handleSubmit}
              >
                {currentQuestionIndex < preQuestions.length - 1
                  ? 'Next'
                  : 'Submit'}
              </button>
            </div>
          ) : (
            <div style={{ margin: 'auto', padding: '10px 40px' }}>
              <button onClick={submitAnswer}>Submit Answer</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModulePreAssessment
