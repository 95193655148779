import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import download1 from '../assets/download1.png'
import download2 from '../assets/download2.png'
import download3 from '../assets/download3.png'
import download4 from '../assets/download4.png'
import boysGrowth from '../assets/files/growth-chart-boy.pdf'
import girlsGrowth from '../assets/files/GGOT-GrowthChart-girl-web.pdf'
import flipchart from '../assets/files/Flipchart.pdf'
import growthDiary from '../assets/files/JX-002539 IMFeD_Growth Diary 2023_Chi R5 OL opt.pdf'
import counseling from '../assets/files/HCP-Info-Card-2020-web.pdf'

const downloadMaterials = () => {
  const openPDF = pdfUrl => {
    window.open(pdfUrl, '_blank')
  }

  return (
    <div>
      <Header />
      <Title text="Materials For IMFeD Practitioners" />

      <div className="download-container">
        <div className="download-small-container">
          <img style={{ width: '250px', height: '200px' }} src={download1} />
          <h3 style={{ color: '#703895' }}>Growth Chart</h3>
          <div>
            Help track a child’s growth & identify if they are at risk of growth
            faltering
          </div>
          <button
            onClick={() => openPDF(boysGrowth)}
            style={{
              width: 'auto',
              marginTop: '10px',
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Boys Growth Chart
          </button>
          <button
            onClick={() => openPDF(girlsGrowth)}
            style={{
              width: 'auto',
              marginTop: '10px',
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Girls Growth Chart
          </button>
        </div>
        <div className="download-small-container">
          <img style={{ width: '250px', height: '200px' }} src={download2} />
          <h3 style={{ color: '#703895' }}>Flipchart</h3>
          <div>Complement your consultation sessions</div>
          <button
            onClick={() => openPDF(flipchart)}
            style={{
              width: 'auto',
              marginTop: '10px',
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Download here
          </button>
        </div>
        <div className="download-small-container">
          <img style={{ width: '250px', height: '200px' }} src={download3} />
          <h3 style={{ color: '#703895' }}>Growth Diary</h3>
          <div>Educate and help parents monitor their child’s growth</div>
          <button
            onClick={() => openPDF(growthDiary)}
            style={{
              width: 'auto',
              marginTop: '10px',
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Download here
          </button>
        </div>
        <div className="download-small-container">
          <img style={{ width: '250px', height: '200px' }} src={download4} />
          <h3 style={{ color: '#703895' }}>Parents Couselling Card</h3>
          <div>
            Complement your consultation with parents on child’s physical
            activity, screen time and sleep habits
          </div>
          <button
            onClick={() => openPDF(counseling)}
            style={{
              width: 'auto',
              marginTop: '10px',
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Download here
          </button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default downloadMaterials
