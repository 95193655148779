import React, { useState } from 'react'
import Header from '../components/Header'
import HTMLFlipBook from 'react-pageflip'
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf'

import samplePDF from '../assets/files/HCP 4th Edition APR 2024-min.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const width = window.innerWidth > 600 ? 500 : 330
const height = window.innerWidth > 600 ? 640 : 420

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref} className="page">
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  )
})

const ReadMe = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <div>
      <Header />
      <div className="container ">
        <div>
          <Document file={samplePDF} style={{ overflow: 'hidden' }}>
            <HTMLFlipBook
              width={width}
              height={height}
              page={currentPage}
              onChange={handlePageChange}
              style={{
                overflow: 'hidden',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Page key={1} pageNumber={1} />

              {Array.from({ length: 39 }, (_, index) => (
                <Page key={index + 2} pageNumber={index + 2} />
              ))}
            </HTMLFlipBook>
          </Document>
        </div>
      </div>
    </div>
  )
}

export default ReadMe
