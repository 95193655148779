import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, message, Progress } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useSelector } from 'react-redux'
import axios from 'axios'

const UpdateProfile = () => {
  const userId = useSelector(state => state.user.user?.data.id)
  const [formData, setFormData] = useState({})
  const [passwordStrength, setPasswordStrength] = useState(0) // 0: Very Weak, 1: Weak, 2: Good, 3: Strong
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  console.log(formData)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post('/api/auth/getUser', {
          userId: userId
        })

        const data = response.data

        if (response.status === 200) {
          setFormData({
            firstname: data.data.firstname,
            lastname: data.data.lastname,
            email: data.data.email,
            placeOfPractice: data.data.placeOfPractice,
            address1: data.data.address1,
            address2: data.data.address2,
            city: data.data.city,
            state: data.data.state,
            password: '',
            confirmPassword: ''
          })
        } else {
          console.error('Failed to fetch user data')
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [userId])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      // Send the updated data to the server
      const response = await axios.post('/api/auth/updateUser', {
        userId: userId,
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        placeOfPractice: formData.placeOfPractice,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        password: formData.password,
        confirmPassword: formData.confirmPassword
      })

      // if (response.status === 200) {
      message.success('Profile updated successfully')
      // } else {
      //     const errorData = await response.json();
      //     message.error(errorData.error || 'Failed to change password');
      //     setIsLoading(false);
      // }
    } catch (error) {
      console.error('Error updating profile:', error)
      message.error(error.response?.data.error || 'An error occurred')
      setIsLoading(false)
    }
  }

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })

    if (fieldName === 'password') {
      const strength = calculatePasswordStrength(e.target.value)
      setPasswordStrength(strength)
    }
  }

  const handleChange2 = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  const calculatePasswordStrength = value => {
    // Your logic to determine password strength
    // You can use regular expressions, length checks, etc.
    // For simplicity, let's assume a basic example here
    if (value.length < 6) {
      return 0 // Very Weak
    } else if (value.length < 10) {
      return 1 // Weak
    } else if (value.match(/[a-zA-Z]+/) && value.match(/[0-9]+/)) {
      return 2 // Good
    } else {
      return 3 // Strong
    }
  }
  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
        return 'red' // Very Weak
      case 1:
        return 'orange' // Weak
      case 2:
        return 'yellow' // Good
      case 3:
        return 'green' // Strong
      default:
        return 'transparent'
    }
  }

  const getPasswordStrengthText = strength => {
    switch (strength) {
      case 0:
        return 'Very Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Good'
      case 3:
        return 'Strong'
      default:
        return ''
    }
  }

  return (
    <div>
      <Header />
      <Title text="Update Your Member Profile" />

      <h1 style={{ color: '#5F5F5F', textAlign: 'center' }}>Edit Profile</h1>

      <Form
        // name="basic"
        style={{
          // maxWidth: 600,
          margin: 'auto', // Center horizontally
          marginTop: '50px', // Adjust the top margin to center vertically
          paddingLeft: '30%',
          paddingRight: '30%'
        }}
        initialValues={{
          remember: true
        }}
        autoComplete="off"
      >
        <p style={{ color: '#757575' }}>*First Name</p>
        <Form.Item
          // name="firstName"
          rules={[
            {
              required: true,
              message: 'Please input your first name!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            placeholder="*First Name"
            value={formData.firstname}
            onChange={e => handleChange(e, 'firstname')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>*Last Name</p>
        <Form.Item
          // name="lastName"
          rules={[
            {
              required: true,
              message: 'Please input your last name!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            placeholder="*Last Name"
            value={formData.lastname}
            onChange={e => handleChange(e, 'lastname')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>*Email Address</p>
        <Form.Item
          // name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!'
            },
            {
              type: 'email',
              message: 'The input is not valid E-mail!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            placeholder="*Email Address"
            value={formData.email}
            onChange={e => handleChange(e, 'email')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>Address 1</p>
        <Form.Item
          // name="Address1"
          rules={[
            {
              required: true,
              message: 'Please input your Address!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            placeholder="*Address 1"
            value={formData.address1}
            onChange={e => handleChange(e, 'address1')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>Address 2</p>
        <Form.Item
        // name="Address2"
        >
          <Input
            className="bottom-border-input"
            placeholder="Address 2"
            value={formData.address2}
            onChange={e => handleChange(e, 'address2')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>City</p>
        <Form.Item
          // name="city"
          rules={[
            {
              required: true,
              message: 'Please input your City!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            name="city"
            placeholder="*City"
            value={formData.city}
            onChange={e => handleChange(e, 'city')}
          />
        </Form.Item>

        <p style={{ color: '#757575' }}>State</p>
        <Form.Item>
          <Select
            name="state"
            placeholder="Select Option"
            value={formData.state}
            onChange={value => handleChange2(value, 'state')}
          >
            <Select.Option value="">Select Option</Select.Option>
            <Select.Option value="johor">Johor</Select.Option>
            <Select.Option value="kedah">Kedah</Select.Option>
            <Select.Option value="kelantan">Kelantan</Select.Option>
            <Select.Option value="melaka">Melaka</Select.Option>
            <Select.Option value="negeri-sembilan">
              Negeri Sembilan
            </Select.Option>
            <Select.Option value="pahang">Pahang</Select.Option>
            <Select.Option value="perak">Perak</Select.Option>
            <Select.Option value="perlis">Perlis</Select.Option>
            <Select.Option value="pulau-pinang">Pulau Pinang</Select.Option>
            <Select.Option value="sabah">Sabah</Select.Option>
            <Select.Option value="sarawak">Sarawak</Select.Option>
            <Select.Option value="selangor">Selangor</Select.Option>
            <Select.Option value="terengganu">Terengganu</Select.Option>
          </Select>
        </Form.Item>

        <p style={{ color: '#757575' }}>Place Of Practice</p>
        <Form.Item
          // name="placeOfPractice"
          rules={[
            {
              required: true,
              message: 'Please input your place of practice!'
            }
          ]}
        >
          <Input
            className="bottom-border-input"
            placeholder="*Place of practice"
            value={formData.placeOfPractice}
            onChange={e => handleChange(e, 'placeOfPractice')}
          />
        </Form.Item>

        <Form.Item name="password">
          <Input.Password
            className="bottom-border-input"
            placeholder="*Password"
            onChange={e => handleChange(e, 'password')}
          />
          <div
            style={{
              marginTop: '8px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Progress
              strokeColor={getPasswordStrengthColor()}
              percent={(passwordStrength + 1) * 25} // Assuming 25% for each strength level
              showInfo={false}
              style={{ width: '30%', alignSelf: 'flex-end' }}
            />
            {/* Password Strength Text */}
            <div
              style={{ color: getPasswordStrengthColor(), alignSelf: 'end' }}
            >
              Strength: {getPasswordStrengthText(passwordStrength)}
            </div>
          </div>
        </Form.Item>
        <Form.Item name="confirmPassword">
          <Input.Password
            className="bottom-border-input"
            placeholder="*Confirm Password"
            onChange={e => handleChange(e, 'confirmPassword')}
          />
        </Form.Item>

        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Button
              loading={isLoading}
              onClick={handleSubmit}
              style={{
                margin: 'auto',
                justifyContent: 'center',
                width: '40%',
                background: 'transparent',
                color: '#6164c1',
                border: '2px solid #6164c1'
              }}
            >
              Update Profile
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Footer />
    </div>
  )
}

export default UpdateProfile
