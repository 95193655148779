import React, { useState } from 'react'
import { Button, Modal, Checkbox, Form, Input } from 'antd'
import axios from 'axios'

const ForgotPassword = ({ isOpen, onClose }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [isLoading, setIsLoading] = useState(false) // Add loading state
  const handleOk = () => {
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const [formData, setFormData] = useState({
    email: ''
  })

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleSubmit = async e => {
    setIsLoading(true)
    e.preventDefault()
    try {
      const response = await axios.post('/api/auth/forgotPassword', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.status === 200) {
        // Successful response
        console.log(
          'Password reset link sent successfully:',
          response.data.message
        )
        setSuccess(response.data.message) // Set the success state
      } else {
        // Non-200 status code, handle the error
        console.error(
          'Error submitting form. Status:',
          response.status,
          'Message:',
          response.data.error
        )
        setError(response.data.error || 'An error occurred')
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error submitting form:', error)
      setError(error.response?.data.error || 'An error occurred')
    }
  }

  return (
    <Modal
      title="Forgot Password"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <div
          style={{
            borderBottom: '1px solid #ddd',
            marginBottom: '15px',
            marginTop: '30px'
          }}
        ></div>
        {error && (
          <div
            style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}
          >
            {error}
          </div>
        )}
        {success && (
          <div
            style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}
          >
            {success}
          </div>
        )}
        <Form
          name="basic"
          style={{
            // maxWidth: 600,
            margin: 'auto', // Center horizontally
            marginTop: '30px', // Adjust the top margin to center vertically
            paddingLeft: '10%',
            paddingRight: '10%'
          }}
          initialValues={{
            remember: true
          }}
          autoComplete="off"
        >
          <p style={{ textAlign: 'left', fontSize: '14px', color: '#757575' }}>
            Please enter your email address or username below.
          </p>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <Input
              className="bottom-border-input"
              placeholder="*Username OR Email Address"
              value={formData.email}
              onChange={e => handleChange(e, 'email')}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: 'center' }}>
              <button
                loading={isLoading}
                onClick={handleSubmit}
                style={{
                  width: '40%',
                  background: 'transparent',
                  color: '#6164c1',
                  border: '2px solid #6164c1'
                }}
              >
                Submit
              </button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
export default ForgotPassword
