import { Form, Input, Button, message } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ForgotPassword = () => {
  const location = useLocation()
  const [userId, setUserId] = useState('')
  const [form] = Form.useForm()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const userId = params.get('userId')
    setUserId(userId)
  }, [location.search])

  const onFinish = async values => {
    try {
      const response = await fetch('/api/auth/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          userId,
          emailReset: true
        })
      })

      if (response.ok) {
        form.resetFields()
        message.success('Password changed successfully')
      } else {
        const errorData = await response.json()
        message.error(errorData.error || 'Failed to change password')
      }
    } catch (error) {
      console.error('Error:', error)
      message.error('An error occurred while changing the password')
    }
  }

  return (
    <div>
      <Header />
      <Title text="Change Password" />

      <h2
        style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}
      >
        Change Password
      </h2>
      <Form
        name="changePasswordForm"
        onFinish={onFinish}
        form={form}
        style={{
          // maxWidth: 600,
          margin: 'auto', // Center horizontally
          marginTop: '50px', // Adjust the top margin to center vertically
          paddingLeft: '35%',
          paddingRight: '35%'
        }}
      >
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password
            className="bottom-border-input"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('The new password that you entered do not match!')
                )
              }
            })
          ]}
        >
          <Input.Password
            className="bottom-border-input"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              background: 'transparent',
              color: '#6164c1',
              border: '2px solid #6164c1'
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Footer />
    </div>
  )
}

export default ForgotPassword
