import Header from '../components/Header';
import Footer from '../components/Footer';
import Title from '../components/Title';

const Disclaimer = () => {
    return (
        <div>
            <Header />

            <div style={{paddingLeft: '25%', paddingRight: '25%'}}>
                <p>Home » Disclaimer</p>
                <h1>Disclaimer</h1>
                <h1>DISCLAIMER</h1>
                <p>This website is produced for educational purposes only. Visitors to this website are strongly advised to consult their doctors for professional medical advice.</p>

                <p>Malaysian Paediatric Association (MPA), Abbott Malaysia and secretariat of IMFeD For Growth (Ultra Works) are not liable for any claims or damages arising from the use of information from this website.</p>

                <p>MPA do not endorse any brand or product, and are not responsible for any claim made in the sponsors’ advertisements.</p>

                <p>Intellectual property rights for this website are vested in the IMFeD For Growth programme. No part of this website may be reproduced without written permission from the IMFeD For Growth Expert Coalition.</p>
            </div>


            <Footer />
        </div>
    );
};

export default Disclaimer;
