import Header from "../../components/Header"
import Title from "../../components/Title";
import parents1 from "../../assets/parents1.png";
import parents2 from "../../assets/parents2.png";
import parents3 from "../../assets/parents3.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Article2 = () => {
    const navigate = useNavigate();
    const paragraphStyle = { color: '#7a7a7a' };
    return (
        <div>

            <Header />
            <Title text="Jadi, Adakah Anak Anda Membesar Dengan Baik?" />
            <div className="article-container">
                <img className="article-image" src={parents2} />


                <p style={paragraphStyle}>Tumbesaran yang lemah dalam kalangan kanak-kanak dapat dilihat dari segi<sup>1</sup></p>

                <ul>
                    <li style={paragraphStyle}>Kebantutan (ketinggian mengikut umur)</li>
                    <li style={paragraphStyle}>Kekurangan berat badan (berat badan rendah mengikut umur)</li>
                    <li style={paragraphStyle}>Penyusutan berat badan (berat badan rendah mengikut ketinggian)</li>
                </ul>

                <p style={paragraphStyle}>Memantau corak tumbesaran anak anda adalah penting bagi mengenal pasti tanda-tanda masalah tumbesaran. Ketika inilah carta tumbesaran mungkin berguna.</p>

                <p style={paragraphStyle}>Carta tumbesaran tersedia mengikut umur dan jantina. Jadi, jangan lupa untuk pilih carta tumbesaran yang sesuai untuk anak anda!</p>

                <p style={paragraphStyle}>Untuk penjelasan lanjut berkenaan cara membaca dan memahami carta tumbesaran, sila rujuk doktor anak anda!</p>

                <h2>Rujukan</h2>
                <ol>
                    <li style={paragraphStyle}>de Onis, M., & Branca, F. (2016). Childhood stunting: A global perspective. Maternal and Child Nutrition, 12, 12–26. <a
                        href="https://doi.org/10.1111/mcn.12231" target="_blank" rel="noopener noreferrer">https://doi.org/10.1111/mcn.12231</a></li>
                </ol>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                <button onClick={() => navigate(-1)} className="button">Kembali</button>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                Artikel berkaitan
            </div>
            <div className="other-article">
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents1} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article1")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            Perlukah anda bimbang akan tumbesaran anak anda?
                        </p>
                    </div>
                </div>
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents3} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article3")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            Adakah anda mahu membantu anak membesar dengan baik?
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Article2;