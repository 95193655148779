import Header from "../components/Header"
import Footer from "../components/Footer";
import Title from "../components/Title"
import { useNavigate } from "react-router-dom";

const Profile = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <Title text="IMFeD Member Profile" />

            <div style={{ justifyContent: 'center', display: 'flex', marginTop: "20px", marginBottom: "20%"}}>
            <button onClick={() => navigate("/updateProfile")} style={{ width: 'auto', backgroundColor: '#5bc0de', borderRadius: "10px", fontWeight: "600", marginRight: "50px" }}>Update your profile</button>
            <button onClick={() => navigate("/changePassword")} style={{ width: 'auto', backgroundColor: '#5bc0de', borderRadius: "10px", fontWeight: "600" }}>Change password</button>
            </div>
            <Footer />
        </div>
    );
}

export default Profile;