import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Title from "../components/Title";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from 'axios';
import { message } from 'antd';

const AskExpert = () => {

    const location = useLocation();
    const userId = useSelector((state) => state.user.user?.data.id);

    const [formData, setFormData] = useState({
        subject: '',
        description: '',
        userId: userId,
        moduleId: location.state,
    });

    const handleChange = (e, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/module/askExpert', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            message.success('Message sent');
        } catch (error) {
            console.error('Error submitting form:', error);
            message.error(error.response?.data.error || 'An error occurred');
        }
    };

    return (
        <div>
            <Header />
            <Title text="E-Learning" />

            <div className='ask-expert-container'>
                <h3 style={{textAlign: 'center'}}>Submit your question</h3>
                <Form
                    name="askExpertForm"
                >
                    <h4>Subject</h4>
                    <Input className='input-expert' value={formData.subject} onChange={(e) => handleChange(e, 'subject')} />

                    <h4>Description</h4>
                    <Input.TextArea className='input-expert' rows={7} value={formData.description} onChange={(e) => handleChange(e, 'description')}/>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                    <button  onClick={() => window.history.back()}>Back</button>
                    <button onClick={handleSubmit} >Submit</button>
                    </div>
                </Form>
            </div>
            <Footer />
        </div>
    );
}

export default AskExpert;
