import React from 'react'
import Header from '../components/Header'
import firstUncle from '../assets/expert/firstUncle.png'
import expert1 from '../assets/expert/expert1.png'
// import expert2 from '../assets/expert/expert2.png'
import expert3 from '../assets/expert/expert3.png'
import expert4 from '../assets/expert/expert4.png'
import expert5 from '../assets/expert/expert5.png'
import expert6 from '../assets/expert/expert6.png'
import expert7 from '../assets/expert/expert7.png'
import expert8 from '../assets/expert/expert8.png'
import expert9 from '../assets/expert/expert9.png'
import expert10 from '../assets/expert/expert10.png'
import expert11 from '../assets/expert/expert11.png'
import expert12 from '../assets/expert/expert12.png'
import expert13 from '../assets/expert/expert13.png'
import Footer from '../components/Footer'
import Title from '../components/Title'

const ExpertPanel = () => {
  return (
    <div>
      <Header />
      <Title text="Expert panel Members" />

      <h3 style={{ textAlign: 'center' }}>Co-Chairs</h3>
      <div className="panel-container">
        <div className="panel-small-container">
          <img src={expert9} alt="expert9" />
          <h5 className="fw-bold">Professor Dr </h5>
          <h5 className="fw-bold" style={{ marginTop: '-1rem' }}>
            Muhammad Yazid Jalaludin
          </h5>
          <div>Senior Consultant Paediatrician &</div>
          <div>Paediatric Endocrinologist</div>
          <div>Kuala Lumpur</div>
        </div>
        <div className="panel-small-container">
          <img src={expert4} alt="expert4" />
          <h5 className="fw-bold">Dr Cheang Hon Kit</h5>
          <div>Consultant Paediatrician & </div>
          <div>Neonatologist</div>
          <div>Georgetown, Penang</div>
        </div>
      </div>

      <h4 style={{ textAlign: 'center' }}>Members</h4>
      <div className="panel-container">
        <div className="panel-small-container">
          <img src={expert1} />
          <h5 className="fw-bold">Dr Alvin Khoh Kim Mun</h5>
          <div>Consultant Paediatrician &</div>
          <div>Paediatric Gastroenterologist </div>
          <div>& Hepatologist </div>
          <div>Kota Kinabalu, Sabah</div>
        </div>
        <div className="panel-small-container">
          <img src={expert3} />
          <h5 className="fw-bold">Assoc Professor </h5>
          <h5 className="fw-bold" style={{ marginTop: '-1rem' }}>
            Dr Azriyanti Anuar Zaini
          </h5>
          <div>Consultant Paediatric Endocrinologist </div>
          <div>Kuala Lumpur</div>
        </div>
        <div className="panel-small-container">
          <img src={expert5} />
          <h5 className="fw-bold">Professor Dr Firdaus Mukhtar</h5>
          <div>Consultant Clinical Psychologist</div>
          <div>Serdang, Selangor</div>
        </div>
      </div>

      <div className="panel-container">
        <div className="panel-small-container">
          <img src={expert7} />
          <h5 className="fw-bold">Ms Khoo An Jo</h5>
          <div>Senior Chief Dietitian</div>
          <div>Georgetown, Penang</div>
        </div>

        <div className="panel-small-container">
          <img src={expert8} />
          <h5 className="fw-bold">Dr Kok Juan Loong</h5>
          <div>Consultant Paediatrician</div>
          <div>Kuching, Sarawak</div>
        </div>

        <div className="panel-small-container">
          <img src={firstUncle} />
          <h5 className="fw-bold">Professor Dr Lee Way Seah</h5>
          <div>Senior Professor</div>
          <div>Kuala Lumpur</div>
        </div>
      </div>

      <div className="panel-container">
        <div className="panel-small-container">
          <img src={expert6} />
          <h5 className="fw-bold">Assoc Professor </h5>
          <h5 className="fw-bold" style={{ marginTop: '-1rem' }}>
            Dr Norazlin Kamal Nor
          </h5>
          <div>Consultant Developmental Paediatrician</div>
          <div>Kuala Lumpur</div>
        </div>
        <div className="panel-small-container">
          <img src={expert10} />
          <h5 className="fw-bold">Ms Rozanna M Rosly</h5>
          <div>Lead Dietitian</div>
          <div>Subang Jaya, Selangor</div>
        </div>
        <div className="panel-small-container">
          <img src={expert11} />
          <h5 className="fw-bold">Ms Siti Hawa Mohd Taib</h5>
          <div>Clinical Dietitian</div>
          <div>Kuala Lumpur</div>
        </div>
      </div>

      <div className="panel-container">
        <div className="panel-small-container">
          <img src={expert12} />
          <h5 className="fw-bold">Dr Thiyagar Nadarajaw</h5>
          <div>Senior Consultant Paediatrician & </div>
          <div>Adolescent Medicine Specialist</div>
          <div>Alor Setar, Kedah</div>
        </div>
        <div className="panel-small-container">
          <img src={expert13} />
          <h5 className="fw-bold">Dr Yong Junina Fadzil</h5>
          <div>Consultant Paediatrician </div>
          <div>& Paediatric Cardiologist</div>
          <div>Petaling Jaya, Selangor</div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ExpertPanel
