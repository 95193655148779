import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { saveToken, saveUser } from '../redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import ForgotPasswordModal from '../components/modal/forgotpassword'
import LegacyUser from '../components/modal/legacyUser'

const Login = ({ isAuthenticated }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false) // Add loading state
  const [email, setEmail] = useState('') // Add state for email
  //   console.log('isAuthenticated', isAuthenticated)
  useEffect(() => {
    if (isAuthenticated && isAuthenticated.isAuthenticated) {
      navigate('/home')
    }
  }, [isAuthenticated, navigate])

  const [
    isForgotPasswordModalVisible,
    setIsForgotPasswordModalVisible
  ] = useState(false)

  const openForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(true)
  }

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(false)
  }

  const handleForgotPassword = () => {
    openForgotPasswordModal()
  }

  const [isLegacyUserModalVisible, setIsLegacyUserModalVisible] = useState(
    false
  )

  const openLegacyUserModal = () => {
    setIsLegacyUserModalVisible(true)
  }

  const closeLegacyUserModal = () => {
    setIsLegacyUserModalVisible(false)
  }

  const handleLegacyUser = () => {
    openLegacyUserModal()
  }

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  })

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    localStorage.removeItem('imfed-token')
    localStorage.removeItem('imfed-user')

    try {
      const response = await axios.post('/api/auth/login', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log(response)
      // navigate("/home");
      if (response.data.user.legacy && !response.data.user.verified) {
        // Open the LegacyUser modal
        setIsLegacyUserModalVisible(true)
        setEmail(response.data.user.email)
        setIsLoading(false)
      } else {
        // Navigate to home if not a legacy user with unverified status
        localStorage.setItem('imfed-token', response.data.token)
        localStorage.setItem('imfed-user', response.data.user.username)
        dispatch(saveToken({ token: response.data.token }))
        dispatch(saveUser(response.data.user))
        window.location.reload()
        // Navigate to "/home" after refreshing
        navigate('/home')
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error submitting form:', error)
      message.error(error.response?.data.error || 'An error occurred')
      // setError(error.response?.data.error || 'An error occurred');
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Header />
      <Title text="Login" />

      <div className="login-text">
        Strictly for healthcare professionals only
      </div>

      <div
        className="login-text"
        style={{ color: 'black', fontSize: '28px', marginTop: '35px' }}
      >
        Please Login
      </div>
      <div>
        <Form
          name="basic"
          style={{
            // maxWidth: 600,
            margin: 'auto', // Center horizontally
            marginTop: '50px', // Adjust the top margin to center vertically
            paddingLeft: '25%',
            paddingRight: '25%'
          }}
          initialValues={{
            remember: true
          }}
          autoComplete="off"
        >
          {error && (
            <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>
          )}
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!'
              }
            ]}
          >
            <Input
              className="bottom-border-input"
              placeholder="*Username"
              value={formData.username}
              onChange={e => handleChange(e, 'username')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input.Password
              className="bottom-border-input"
              placeholder="*Password"
              value={formData.password}
              onChange={e => handleChange(e, 'password')}
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Checkbox>Remember me</Checkbox>
              {/* <ForgotPassword /> */}
              <a
                onClick={handleForgotPassword}
                style={{ cursor: 'pointer', color: '#703895' }}
              >
                Lost your password
              </a>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={isLoading} // Use the loading prop to show/hide the loader
              style={{
                background: 'transparent',
                color: '#703895',
                border: '2px solid #703895',
                width: '200px'
              }}
            >
              Login
            </Button>
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p style={{ margin: 0, color: 'gray' }}>Don't have an account? </p>
            <span
              onClick={() => navigate('/register')}
              style={{ cursor: 'pointer', marginLeft: '5px', color: '#703895' }}
            >
              {' '}
              SIGNUP
            </span>
          </div>
        </Form>
      </div>
      <ForgotPasswordModal
        isOpen={isForgotPasswordModalVisible}
        onClose={closeForgotPasswordModal}
      />
      <LegacyUser
        isOpen={isLegacyUserModalVisible}
        onClose={closeLegacyUserModal}
        userEmail={email}
      />

      <Footer />
    </div>
  )
}

export default Login
