import Header from "../../components/Header"
import Title from "../../components/Title";
import parents1 from "../../assets/parents1.png";
import parents2 from "../../assets/parents2.png";
import parents3 from "../../assets/parents3.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Article3 = () => {

    const navigate = useNavigate();

    const blueBorderStyle = {
        border: '1px solid #7a7a7a',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '20px'
    };

    const paragraphStyle = { color: '#7a7a7a' };
    const paragraphStyle2 = { color: '#712e86', fontWeight: '600', margin: 0 };
    return (
        <div>

            <Header />
            <Title text="Adakah Anda Mahu Membantu Anak Anda Membesar Dengan Baik?" />
            <div className="article-container">
                <img className="article-image" src={parents3} />

                <p style={paragraphStyle}>Anda sentiasa boleh mendapatkan sokongan terbaik daripada doktor anak anda!</p>

                <p style={paragraphStyle}>Sebagai ibu bapa, mungkin sukar untuk meluangkan masa melawat doktor anak anda sambil mengimbangi tanggungjawab kerjaya dan rumah tangga. Kita mungkin berasa tidak berani untuk berjumpa doktor.</p>

                <p style={paragraphStyle}>Walau bagaimanapun, jangan bimbang, sedikit persediaan mungkin dapat membantu.</p>
                <div style={blueBorderStyle}>
                    <h3 style={paragraphStyle2}><strong>Sebelum Berjumpa Doktor</strong></h3>
                    <ol>
                        <li style={paragraphStyle}>Membuat catatan mengenai masalah yang anda ingin bincangkan sebelum berjumpa doktor.</li>
                        <li style={paragraphStyle}>Rekodkan sebarang gejala, tingkah laku atau corak pemakanan yang anak anda alami.</li>
                        <li style={paragraphStyle}>Bawa senarai ubat anak anda (jika ada).</li>
                        <li style={paragraphStyle}>Bawa rekod imunisasi anak anda.</li>
                    </ol>
                </div>

                <div style={blueBorderStyle}>
                    <h3 style={paragraphStyle2}><strong>Semasa Berjumpa Doktor</strong></h3>

                    <p style={paragraphStyle}>Berikut adalah beberapa soalan yang mungkin boleh digunakan dalam perbincangan dengan doktor anak anda:</p>

                    <ul>
                        <li style={paragraphStyle}><strong>Pengukuran tumbesaran</strong>
                            <ul>
                                <li style={paragraphStyle}>Apakah maksud persentil?</li>
                                <li style={paragraphStyle}>Adakah pengukuran anak saya telah berubah sejak lawatan terakhir?</li>
                                <li style={paragraphStyle}>Adakah pengukuran anak saya menunjukkan masalah tumbesaran?</li>
                                <li style={paragraphStyle}>Apakah yang boleh saya lakukan untuk membantu anak saya membesar?</li>
                                <li style={paragraphStyle}>Berapakah ketinggian pertengahan ibu bapa bagi anak saya dan adakah anak saya berpotensi untuk mencapainya?</li>
                            </ul>
                        </li>

                        <li style={paragraphStyle}><strong>Pemberian makanan, pemakanan dan tingkah laku</strong>
                            <ul>
                                <li style={paragraphStyle}>Adakah anak saya makan terlalu sedikit?</li>
                                <li style={paragraphStyle}>Apakah jenis makanan yang boleh dimakan oleh anak saya?</li>
                                <li style={paragraphStyle}>Bagaimana saya harus memperkenalkan makanan baru kepada anak saya?</li>
                                <li style={paragraphStyle}>Apa yang boleh saya lakukan sekiranya anak saya makan terlalu sedikit?</li>
                                <li style={paragraphStyle}>Berapa lamakah tidur yang mencukupi untuk anak saya?</li>
                                <li style={paragraphStyle}>Bagaimana saya boleh mendorong aktiviti fizikal untuk anak saya?</li>
                                <li style={paragraphStyle}>Adakah saya perlu berjumpa doktor pakar atau pakar dietetik atau pakar psikologi?</li>
                                <li style={paragraphStyle}>Apakah langkah seterusnya?</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                <button onClick={() => navigate(-1)} className="button">Kembali</button>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                Artikel berkaitan
            </div>
            <div className="other-article">
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents1} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article1")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            Perlukah anda bimbang akan tumbesaran anak anda?
                        </p>
                    </div>
                </div>
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents2} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article2")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>

                            Jadi, adakah anak anda membesar dengan baik?
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Article3;