import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { Carousel } from 'antd'
import testImage from '../assets/carousel1.png'
import testImage2 from '../assets/carousel2.png'
import testImage3 from '../assets/carousel3.png'
import testImage6 from '../assets/carousel4.png'
import testImage4 from '../assets/featureImage.png'
import healthcareimg from '../assets/healthcareImg.png'
import { useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ForceUpdateModal from '../components/modal/forceupdate'
import pdf from '../assets/files/HCP 4th Edition APR 2024-min.pdf'

const Healthcare = () => {
  const navigate = useNavigate()

  const [userData, setUserData] = useState(null)
  const [isForceModalOpen, setForceModalOpen] = useState(false)
  // console.log(userData);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = localStorage.getItem('imfed-token')

        if (!token) {
          navigate('/login')
        }

        const response = await axios.post('/api/auth/checkToken', { token })
        setUserData(response.data.data)
        // console.log(response.data.data.userData.icNumber)
        if (!response.data.data.userData.icNumber) {
          setForceModalOpen(true)
        }
      } catch (error) {
        // Handle errors, e.g., if the token is expired or user not found
        console.error(error)
      }
    }

    checkToken()
  }, [])

  const handleDownload = () => {
    // You can customize the download behavior here
    const link = document.createElement('a')
    link.href = pdf
    link.download = 'HCP 4th Edition APR 2024-min.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      <Header />
      <Title text="Healthcare Professional" />
      <div className="container ">
        <div className="left-right-10percent-margin">
          <div style={{ border: 'none' }}>
            <Carousel autoplay>
              <div>
                <img
                  src={testImage}
                  alt="img1"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div>
                <img
                  src={testImage2}
                  alt="img2"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div>
                <img
                  src={testImage3}
                  alt="img3"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <div>
                <img
                  src={testImage6}
                  alt="img4"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </Carousel>
          </div>

          <div className="mt-5 ">
            <iframe
              width="100%"
              height="400px"
              src="https://www.youtube.com/embed/pkFt8qsOtxs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ borderRadius: '15px 15px 0 0' }}
            ></iframe>
            <div
              style={{ borderTop: 'none', marginTop: '-1.5rem' }}
              className="purple-border"
            >
              <h2 className="video-title text-center pt-4">E-Learning</h2>
              <h6 className="video-description text-center py-4">
                Learn the fundamentals of childhood growth from experts in the
                field
              </h6>
              <div
                style={{
                  gap: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <button onClick={() => navigate('/module')}>
                  Paediatric Growth & Nutrition Online Course
                </button>
                <button onClick={() => navigate('/materials')}>
                  Past Lectures
                </button>
              </div>
            </div>
          </div>

          <div
            className="purple-border mt-4  two-column-container"
            style={{ alignItems: 'center' }}
          >
            <div>
              <h3 className="feature-title text-center mx-3">
                MEASURE, IDENTIFY & MANAGE Growth Faltering in Children (3rd
                Edition)
              </h3>
              <div
                style={{ display: 'grid', justifyContent: 'center' }}
                className="mt-4"
              >
                <button onClick={() => navigate('/readMe')}>Read here</button>
                <button style={{ marginTop: '10px' }} onClick={handleDownload}>
                  Download
                </button>
              </div>
            </div>
            <div>
              <img src={testImage4} className="w-100" alt="img4" />
            </div>
          </div>

          <div className="mt-3">
            <div
              className="purple-border two-column-container"
              style={{ alignItems: 'center' }}
            >
              <div className="text-center">
                <h3 className="feature-title">
                  Running low on your IMFeD Materials?
                </h3>
                <button
                  onClick={() => navigate('/downloadMaterials')}
                  className="mt-4"
                >
                  Download here
                </button>
              </div>
              <div>
                <img src={healthcareimg} className="w-100" alt="healthcare" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForceUpdateModal
        isOpen={isForceModalOpen}
        onClose={() => setForceModalOpen(false)}
      />
      <Footer />
    </div>
  )
}

export default Healthcare
