import React, { useRef, useEffect, useState } from 'react'
import cert1 from '../assets/certs/OLC e-certs-01.png'
import cert2 from '../assets/certs/OLC e-certs-02.png'
import cert3 from '../assets/certs/OLC e-certs-03.png'
import cert4 from '../assets/certs/OLC e-certs-04.png'
import cert5 from '../assets/certs/OLC e-certs-05.png'
import cert6 from '../assets/certs/OLC e-certs-06.png'
import cert7 from '../assets/certs/OLC e-certs-07.png'
// import certBgImage from '../assets/E-certs Advocate-1.png'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import jsPDF from 'jspdf'

const certObj = {
  1: cert1,
  2: cert2,
  3: cert3,
  4: cert4,
  5: cert5,
  6: cert6,
  7: cert7
}

const ModuleCertificate = () => {
  const location = useLocation()
  const moduleId = location.state
  const navigate = useNavigate()
  const userId = useSelector(state => state.user.user?.data.id)
  const firstname = useSelector(
    state => state.user.user?.data.userData.firstname
  )
  const lastname = useSelector(state => state.user.user?.data.userData.lastname)

  const fullName = `${firstname} ${lastname}`

  const canvasRef = useRef(null)
  const [certificateData, setCertificateData] = useState([])
  const [module, setModule] = useState('')

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const responseModule = await axios.post('/api/module/getModuleById', {
          moduleId
        })
        setModule(responseModule.data.data)
      } catch (error) {
        console.error('Error fetching questions:', error)
        setCertificateData([])
      }
    }

    fetchCertificate()
  }, [moduleId])

  // console.log('module', module)

  useEffect(() => {
    const fetchCertificateStatus = async () => {
      try {
        const response = await axios.post(
          '/api/module/getCertificateStatusInformation',
          { moduleId, userId }
        )

        // console.log('response ---', response)
        setCertificateData(response.data.data)
      } catch (error) {
        console.error('Error fetching questions:', error)
        setCertificateData([])
      }
    }

    if (moduleId && userId) {
      fetchCertificateStatus()
    }
  }, [moduleId, userId])

  const handleNextClick = async () => {
    try {
      const response = await axios.post('/api/module/nextModule', {
        moduleId: location.state,
        userId: userId
      })

      const data = response.data

      if (response.status === 200) {
        // Update location state with the next module ID
        navigate('/moduleContent', { state: data.data.id })
      } else {
        console.error('Failed to move to the next module')
      }
    } catch (error) {
      console.error('Error moving to the next module:', error)
    }
  }

  const handleDownload = async () => {
    const doc = new jsPDF('portrait', 'px', 'a4')

    // Load the image onto the document
    const imageWidth = 447 // Desired width of the image in the PDF
    doc.addImage(certObj[module?.order], 'PNG', 0, 0, imageWidth, 632) // Adjust the position and size as per your requirement
    doc.setFontSize(18)
    doc.setFont('Poppins')
    doc.setTextColor('#fff')
    doc.text(fullName, doc.internal.pageSize.getWidth() / 2, 325, {
      align: 'center'
    })

    const certifiedDate = new Date(certificateData[0]?.certified_date)
    // Get day, month, and year
    const day = certifiedDate
      .getDate()
      .toString()
      .padStart(2, '0')
    const month = (certifiedDate.getMonth() + 1).toString().padStart(2, '0')
    const year = certifiedDate.getFullYear().toString()

    // Concatenate day, month, and year with dashes
    const formattedDate = `${day}-${month}-${year}`
    doc.setFontSize(14)
    doc.text(formattedDate, doc.internal.pageSize.getWidth() / 2, 473, {
      align: 'center'
    })
    // Set the text color (here, it's red)
    // Save the PDF as a file
    doc.save(`${fullName}-module${module?.order}-certificate.pdf`)

    try {
      const response = await axios.post('/api/module/downloadCertificate', {
        moduleId: location.state,
        userId: userId
      })

      if (response.status === 200) {
        console.log('downloaded complete')
      } else {
        console.error('Failed when downloading certificate')
      }
    } catch (error) {
      console.error('Error when download the certificate:', error)
    }
  }
  // console.log('certificateData', certificateData)
  useEffect(() => {
    if (certificateData.length > 0 && certificateData.length > 0) {
      const certifiedDate = new Date(certificateData[0].certified_date)

      // Get day, month, and year
      const day = certifiedDate
        .getDate()
        .toString()
        .padStart(2, '0')
      const month = (certifiedDate.getMonth() + 1).toString().padStart(2, '0')
      const year = certifiedDate.getFullYear().toString()

      // Concatenate day, month, and year with dashes
      const formattedDate = `${day}-${month}-${year}`

      // console.log(formattedDate) // Example output: "18-03-2024"

      const drawCertificate = async () => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')

        try {
          // const imageUrl = certificateData[0].image_url
          const response = await fetch(certObj[module?.order])
          // console.log('response', response)
          if (!response.ok) {
            throw new Error('Failed to fetch image')
          }

          const blob = await response.blob()
          const objectURL = URL.createObjectURL(blob)

          const certificateImage = new Image()
          certificateImage.onload = () => {
            ctx.drawImage(certificateImage, 0, 0, canvas.width, canvas.height)

            const textX = canvas.width / 2
            const textY = canvas.height / 2

            ctx.font = '18px Poppins'
            ctx.fillStyle = 'white'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillText(`${fullName}`, textX, textY + 5)
            ctx.font = '14px Poppins'
            ctx.fillText(`${formattedDate}`, textX, textY + 160)
          }
          certificateImage.src = objectURL
        } catch (error) {
          console.error('Error loading certificate image:', error)
        }
      }

      drawCertificate()
    }
  }, [certificateData, certificateData])

  return (
    <div className="video">
      <div className="certificate-container">
        <div style={{ padding: '50px 30px', display: 'flex' }}>
          <div className="pe-3">
            <h2 className="fw-bold">Congratulations</h2>
            <h4>Here's your certificate for completing the module</h4>
            <button
              style={{ width: '75%', margin: '10px 0' }}
              onClick={handleDownload}
            >
              Download
            </button>
            <button onClick={handleNextClick} style={{ width: '75%' }}>
              Next
            </button>
          </div>

          <div
            style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}
          >
            <canvas ref={canvasRef} width={400} height={650} />
            {/* <canvas ref={canvasRef} width={550} height={400} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleCertificate
