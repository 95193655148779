import Header from "../../components/Header"
import Title from "../../components/Title";
import parents1 from "../../assets/parents1.png";
import parents2 from "../../assets/parents2.png";
import parents3 from "../../assets/parents3.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Article = () => {

    const navigate = useNavigate();
    return (
        <div>

            <Header />
            <Title text="Should You Be Concerned Over Your Child’s Growth?" />
            <div className="article-container">
                <img className="article-image" src={parents1} />

                <p style={{ color: '#7a7a7a' }}>As parents, you strive to ensure that your child receives the best of everything to secure a brighter future for them. However, despite your child receives, there is a tendency to overlook the importance of optimal growth in your child while having to juggle between the day-to-day activities.</p>

                <p style={{ color: '#7a7a7a' }}>Growth is an important indicator for your child’s overall well-being and development<sup>1</sup>.</p>

                <p style={{ color: '#7a7a7a' }}>Poor growth is caused by several reasons including poor nutrition, feeding difficulties, frequent illnesses, and underlying chronic health issues<sup>2</sup>.</p>

                <p style={{ color: '#7a7a7a' }}>This may lead to<sup>3</sup>.</p>

                <ul>
                    <li style={{ color: '#7a7a7a' }}>Higher risk of infections</li>
                    <li style={{ color: '#7a7a7a' }}>Poor brain development and social skills</li>
                    <li style={{ color: '#7a7a7a' }}>Poor school performance</li>
                    <li style={{ color: '#7a7a7a' }}>Shorter adult height</li>
                    <li style={{ color: '#7a7a7a' }}>Lower earnings and poorer productivity in adulthood</li>
                    <li style={{ color: '#7a7a7a' }}>Increased risk of chronic diseases in adulthood</li>
                </ul>

                <p style={{ color: '#7a7a7a' }}>Childhood growth and development is crucial in the first 5 years of life<sup>4</sup>. By the age of 5, your child should achieve 60% of their adult height<sup>5</sup>. Therefore, early detection of any hiccups in your child’s growth and early treatment may promise a more successful future for your child.</p>

                <h2>References</h2>
                <ol>
                    <li style={{ color: '#7a7a7a' }}>Millward, D. J. (2017, June 1). Nutrition, infection and stunting: The roles of deficiencies of individual nutrients and foods, and of inflammation, as determinants of reduced linear growth of children. Nutrition Research Reviews. Cambridge University Press. <a
                        href="https://doi.org/10.1017/S0954422416000238" target="_blank">https://doi.org/10.1017/S0954422416000238</a></li>
                    <li style={{ color: '#7a7a7a' }}>World Health Organisation. (2014). Global nutrition targets 2025: Stunting policy brief. Geneva: Geneva: World Health Organisation.</li>
                    <li style={{ color: '#7a7a7a' }}>de Onis, M., & Branca, F. (2016). Childhood stunting: A global perspective. Maternal and Child Nutrition, 12, 12–26. <a
                        href="https://doi.org/10.1111/mcn.12231" target="_blank">https://doi.org/10.1111/mcn.12231</a></li>
                    <li style={{ color: '#7a7a7a' }}>Raising Children Network (RCN). (2020). Child development: the first five years. Retrieved June 16, 2021, from <a
                        href="https://raisingchildren.net.au/toddlers/development/understanding-development/development-first-five-years" target="_blank">https://raisingchildren.net.au/toddlers/development/understanding-development/development-first-five-years</a></li>
                    <li style={{ color: '#7a7a7a' }}>National Center for Chronic Disease Prevention and Health Promotion. 2 to 20 Years: Boys Stature Weight-for-Age Percentiles.; 2000. <a
                        href="https://www.cdc.gov/growthcharts/" target="_blank">https://www.cdc.gov/growthcharts/</a></li>
                </ol>
            </div>


            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                <button onClick={() => navigate(-1)} className="button">Back</button>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                Related Article
            </div>

            <div className="other-article">
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents2} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/article2")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            So, how well is your child growing?
                        </p>
                    </div>
                </div>
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents3} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/article2")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            Want to help your child grow optimally?
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Article;