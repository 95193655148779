import React, { useState } from 'react'
import { Button, Modal, Checkbox, Form, Input } from 'antd'

const LegacyUser = ({ isOpen, onClose, userEmail }) => {
  const handleOk = () => {
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Modal
      title="Check your Email"
      width={800}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            borderBottom: '1px solid #ddd',
            marginBottom: '15px',
            marginTop: '30px'
          }}
        ></div>
        <p
          style={{
            fontFamily: 'Poppins',
            fontSize: '17px',
            fontWeight: 400
          }}
        >
          Welcome back! To enhance your account security after our recent system
          update, we've sent a verification link to your registered email{' '}
          <span style={{ color: '#FF0000' }}>{userEmail}</span>{' '}
        </p>
        <p
          style={{
            fontFamily: 'Poppins',
            fontSize: '17px',
            fontWeight: 400
          }}
        >
          Please check your inbox (and spam folder) for the link. Clicking it
          will ensure your account remains secure. Thank you for helping us keep
          your information safe!
        </p>
        <Form.Item>
          <div style={{ textAlign: 'center' }}>
            <button
              onClick={handleCancel}
              style={{
                width: '40%',
                background: 'transparent',
                color: '#6164c1',
                border: '2px solid #6164c1'
              }}
            >
              Close
            </button>
          </div>
        </Form.Item>
      </div>
    </Modal>
  )
}
export default LegacyUser
