import Header from '../components/Header';
import Footer from '../components/Footer';
import Title from '../components/Title';

const Tnc = () => {
    return (
        <div>
            <Header />

            <div style={{ paddingLeft: '25%', paddingRight: '25%' }}>
                <p>Home » Terms & Condition</p>
                <h1>Terms & Condition</h1>
                <h1>TERMS AND CONDITIONS</h1>
                <p>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using www.imfed.my (“Site”). IMFeD For Growth is collectively owned by Malaysia Paediatric Association (MPA) and Abbott Malaysia. This programme is funded by corporate sponsors and operated by Ultra Works as programme secretariat. MPA, Abbott Malaysia and Ultra Works will henceforth be referred to as “us”, “we’ or “our”.</p>

                <p>Your access to and use of the Site is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Site.</p>

                <p>By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Site.</p>

                <h1>Limitations</h1>
                <p>In no event shall we be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Site, even if we have been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

                <h1>Revisions and Errata</h1>
                <p>The materials appearing on this Site could include technical, typographical, or photographic errors. We do not warrant that any of the materials on this Site are accurate, complete or current. We may make changes to the materials contained on this Site at any time without notice. We do not, however, make any commitment to update the materials.</p>

                <h1>Links To Other Websites</h1>
                <p>Our Site may contain links to third-party web sites or services that are not owned or controlled by us.</p>

                <p>We have no control over, and assumes no responsibility for, the content, privacy policies or practices of any third part websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>

                <h1>Changes</h1>
                <p>We reserve the right at our sole discretion, to modify or replace these Terms at any time without notice. By using this website, you are agreeing to be bound by the then current version of these Terms.</p>

                <h1>Governing Law</h1>
                <p>Any claim relating to this website shall be governed by the laws of Malaysia without regard to its conflict of law provisions.</p>
            </div>


            <Footer />
        </div>
    );
};

export default Tnc;
