import React from 'react'
import Header from '../components/Header'
import aboutImage from '../assets/aboutImage.jpg'
import number1 from '../assets/number1.png'
import number2 from '../assets/number2.png'
import number3 from '../assets/number3.png'
import measure from '../assets/measure.png'
import identify from '../assets/identify.png'
import manage from '../assets/manage.png'
import Footer from '../components/Footer'
import Title from '../components/Title'

const About = () => {
  return (
    <>
      <Header />
      <Title text="About us" />
      <div className="container">
        <div className="two-column-container purple-background">
          <div className="mb-4">
            <img
              className="image-contain width-50-100"
              src={aboutImage}
              alt="About Us"
            />
          </div>
          <div className="text-white-bg">
            <p>
              IMFeD Malaysia was launched on March 26, 2012 by the Malaysian
              Paediatric Association (MPA). The first 5 years (2012-2017)
              focused on adapting, localising and introducing a system to
              identify and manage feeding difficulties. In the following 5 years
              (2018-2023), the scope expanded to include screening and
              intervention for growth faltering related to feeding and nutrition
              issues.
            </p>
            <p>
              To date, IMFeD Malaysia is known publicly as IMFeD for Growth.
            </p>
          </div>
        </div>

        <div className="strategy-container p-3">
          <h3 style={{ textAlign: 'center', color: '#703895' }}>
            Programme Strategies
          </h3>

          <div className="strategy-section">
            <div className="strategy-item">
              <div className="strategy-image">
                <img src={number1} alt="1" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Equipping healthcare professionals with essential knowledge &
                  skills related to childhood growth, feeding & nutrition via
                  trainings and workshops.
                </p>
              </div>
            </div>

            <div className="strategy-item">
              <div className="strategy-image">
                <img src={number2} alt="2" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Developing informative resources and tools for counselling and
                  educating parents on the importance of monitoring children’s
                  growth
                </p>
              </div>
            </div>

            <div className="strategy-item">
              <div className="strategy-image">
                <img src={number3} alt="3" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Organizing parent campaigns to empower parents in optimizing
                  their children's growth.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="strategy-container" style={{ border: 'none' }}>
          <h3 style={{ textAlign: 'center', color: '#703895' }}>
            The IMFeD Principles
          </h3>

          <div className="strategy-section">
            <div className="strategy-item">
              <div className="strategy-image">
                <img src={measure} alt="img1" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Measure and assess children’s growth status
                </p>
              </div>
            </div>

            <div className="strategy-item">
              <div className="strategy-image">
                <img src={identify} alt="img2" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Identify risk factors that may impede growth
                </p>
              </div>
            </div>

            <div className="strategy-item">
              <div className="strategy-image">
                <img src={manage} alt="img3" />
              </div>
              <div className="strategy-text">
                <p style={{ color: '#7a7a7a', fontSize: '18px' }}>
                  Manage & optimise children’s growth through appropriate
                  feeding & nutritional interventions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
