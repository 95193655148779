import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { message } from 'antd';
import { saveToken, saveUser } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import axios from 'axios';

const Legacy = () => {
    const location = useLocation();
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const datems = params.get('datems');
    
        if (!datems) {
            message.error('Link invalid');
            return;
        }
    
        const thisDate = new Date(parseInt(datems));
        const today = new Date();
    
        // Compare the dates directly
        if (
            thisDate.getDate() !== today.getDate() ||
            thisDate.getMonth() !== today.getMonth() ||
            thisDate.getFullYear() !== today.getFullYear()
        ) {
            message.error('Link expired');
            return;
        }
    
        const userId = params.get('userId');
        setUserId(userId);
    
        if (userId && datems) {
            legacyUser(userId);
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    

    // useEffect(() => {
    //     const params = new URLSearchParams(location.search);
    //     const userId = params.get('userId');
    //     setUserId(userId);
    //     if (userId) {
    //         legacyUser(userId);
    //     }
    // }, [location.search]);

    const legacyUser = async (userId) => {
        try {
            const response = await axios.post('/api/auth/legacy', { userId }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log(response);

            message.success('Account verified!');
            localStorage.setItem("imfed-token", response.data.token);
            dispatch(saveToken({ token: response.data.token }));
            dispatch(saveUser(response.data.user));

            setTimeout(() => {
                navigate('/healthcare');
              }, 500);
        } catch (error) {
            console.error('Error legacying user:', error.message);
            message.error('Invalid Link');
        }
    };

    return (
        <div>
            <Header />
            <h1 style={{ textAlign: 'center' }}>Thank you</h1>
        </div>
    );
};

export default Legacy;
