import Header from "../../components/Header"
import Title from "../../components/Title";
import parents1 from "../../assets/parents1.png";
import parents2 from "../../assets/parents2.png";
import parents3 from "../../assets/parents3.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Article2 = () => {

    const navigate = useNavigate();
    const paragraphStyle = { color: '#7a7a7a' };
    return (
        <div>

            <Header />
            <Title text="So, how well is your child growing?" />
            <div className="article-container">
                <img className="article-image" src={parents2} />


                <p style={paragraphStyle}>Poor growth among children can be presented in terms of<sup>1</sup></p>

                <ul>
                    <li style={paragraphStyle}>Stunting (short-for-age)</li>
                    <li style={paragraphStyle}>Underweight (low weight-for-age)</li>
                    <li style={paragraphStyle}>Wasting (low weight-for-height)</li>
                </ul>

                <p style={paragraphStyle}>Monitoring the pattern of your child’s growth is important to help identify signs of poor growth. This is when a growth chart may be useful.</p>

                <p style={paragraphStyle}>Growth charts are available according to different age groups and gender; remember to pick the correct growth chart for your child!</p>

                <p style={paragraphStyle}>For more explanation on how to interpret the growth chart, do not hesitate to talk to your child’s doctor!</p>

                <h2>References</h2>
                <ol>
                    <li style={paragraphStyle}>de Onis, M., & Branca, F. (2016). Childhood stunting: A global perspective. Maternal and Child Nutrition, 12, 12–26. <a
                        href="https://doi.org/10.1111/mcn.12231" target="_blank" rel="noopener noreferrer">https://doi.org/10.1111/mcn.12231</a></li>
                </ol>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                <button onClick={() => navigate(-1)} className="button">Back</button>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                Related Article
            </div>
            <div className="other-article">
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents1} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/article1")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px' }}>
                            Should you be concerned over your child’s growth?
                        </p>
                    </div>
                </div>
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents3} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/article3")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px' }}>
                            Want to help your child grow optimally?
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Article2;