import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  Progress
} from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { saveToken, saveUser } from '../../redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
const { TextArea } = Input

const ForceUpdateModal = ({ isOpen, onClose }) => {
  const handleOk = () => {
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const userId = useSelector(state => state.user.user?.data.id)
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post('/api/auth/getUser', {
          userId: userId
        })

        const data = response.data

        if (response.status === 200) {
          setFormData({
            firstname: data.data.firstname,
            lastname: data.data.lastname,
            email: data.data.email,
            placeOfPractice: data.data.placeOfPractice,
            address1: data.data.address1,
            address2: data.data.address2,
            city: data.data.city,
            state: data.data.state,
            mmcNumber: data.data.mmcNumber,
            job: data.data.job,
            refer: data.data.refer,
            icNumber: data.data.icNumber,
            otherProfession: data.data.otherProfession
          })
        } else {
          console.error('Failed to fetch user data')
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [userId])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      // Send the updated data to the server
      const response = await axios.post('/api/auth/forceUpdate', {
        userId: userId,
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        placeOfPractice: formData.placeOfPractice,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        mmcNumber: formData.mmcNumber,
        job: formData.job,
        refer: formData.refer,
        icNumber: formData.icNumber,
        password: formData.password,
        otherProfession: formData.otherProfession
      })

      // if (response.status === 200) {
      message.success('Profile updated successfully')
      onClose()
      // } else {
      //     const errorData = await response.json();
      //     message.error(errorData.error || 'Failed to change password');
      //     setIsLoading(false);
      // }
    } catch (error) {
      console.error('Error updating profile:', error)
      message.error(error.response?.data.error || 'An error occurred')
      setIsLoading(false)
    }
  }

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleChange2 = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return (
    <div>
      <Modal
        title="Update your profile"
        open={isOpen}
        footer={null}
        width={700}
      >
        <div>
          <div
            style={{
              borderBottom: '1px solid #ddd',
              marginBottom: '15px',
              marginTop: '30px'
            }}
          ></div>

          <Form
            // name="basic"
            style={{
              // maxWidth: 600,
              margin: 'auto', // Center horizontally
              marginTop: '50px', // Adjust the top margin to center vertically
              paddingLeft: '10%',
              paddingRight: '10%'
            }}
            initialValues={{
              remember: true
            }}
            autoComplete="off"
          >
            <p style={{ color: '#757575' }}>*First Name</p>
            <Form.Item
              // name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*First Name"
                value={formData.firstname}
                onChange={e => handleChange(e, 'firstname')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>*Last Name</p>
            <Form.Item
              // name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*Last Name"
                value={formData.lastname}
                onChange={e => handleChange(e, 'lastname')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>New Password</p>
            <Form.Item name="password">
              <Input.Password
                className="bottom-border-input"
                placeholder="*New Password"
                onChange={e => handleChange(e, 'password')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>*NRIC ( Identity Card Number )</p>
            <Form.Item
              // name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input your NRIC'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="* Update your NRIC"
                value={formData.icNumber}
                onChange={e => handleChange(e, 'icNumber')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>*Email Address</p>
            <Form.Item
              // name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!'
                },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*Email Address"
                value={formData.email}
                onChange={e => handleChange(e, 'email')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>*MMC Number</p>
            <Form.Item
              // name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your MMC Number!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*MMC Number"
                value={formData.mmcNumber}
                onChange={e => handleChange(e, 'mmcNumber')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>Profession</p>
            <Form.Item>
              <Select
                style={{ textAlign: 'left' }}
                className="register-border-input"
                name="job"
                placeholder="Select Option"
                value={formData.job}
                onChange={value => handleChange2(value, 'job')}
              >
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value="paediatrician">
                  Paediatrician
                </Select.Option>
                <Select.Option value="generalPractitioner">
                  General Practitioner
                </Select.Option>
                <Select.Option value="medicalOfficer">
                  Medical Officer
                </Select.Option>
                <Select.Option value="others">
                  Others ( Please state in box below )
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <TextArea
                className="register-border-input"
                value={formData.otherProfession}
                onChange={e => handleChange(e, 'otherProfession')}
                rows={4}
                placeholder="If selected other profession, please state here."
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>Place Of Practice</p>
            <Form.Item
              // name="placeOfPractice"
              rules={[
                {
                  required: true,
                  message: 'Please input your place of practice!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*Place of practice"
                value={formData.placeOfPractice}
                onChange={e => handleChange(e, 'placeOfPractice')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>Address 1</p>
            <Form.Item
              // name="Address1"
              rules={[
                {
                  required: true,
                  message: 'Please input your Address!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                placeholder="*Address 1"
                value={formData.address1}
                onChange={e => handleChange(e, 'address1')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>Address 2</p>
            <Form.Item
            // name="Address2"
            >
              <Input
                className="bottom-border-input"
                placeholder="Address 2"
                value={formData.address2}
                onChange={e => handleChange(e, 'address2')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>City</p>
            <Form.Item
              // name="city"
              rules={[
                {
                  required: true,
                  message: 'Please input your City!'
                }
              ]}
            >
              <Input
                className="bottom-border-input"
                name="city"
                placeholder="*City"
                value={formData.city}
                onChange={e => handleChange(e, 'city')}
              />
            </Form.Item>

            <p style={{ color: '#757575' }}>State</p>
            <Form.Item>
              <Select
                name="state"
                placeholder="Select Option"
                value={formData.state}
                onChange={value => handleChange2(value, 'state')}
              >
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value="johor">Johor</Select.Option>
                <Select.Option value="kedah">Kedah</Select.Option>
                <Select.Option value="kelantan">Kelantan</Select.Option>
                <Select.Option value="melaka">Melaka</Select.Option>
                <Select.Option value="negeri-sembilan">
                  Negeri Sembilan
                </Select.Option>
                <Select.Option value="pahang">Pahang</Select.Option>
                <Select.Option value="perak">Perak</Select.Option>
                <Select.Option value="perlis">Perlis</Select.Option>
                <Select.Option value="pulau-pinang">Pulau Pinang</Select.Option>
                <Select.Option value="sabah">Sabah</Select.Option>
                <Select.Option value="sarawak">Sarawak</Select.Option>
                <Select.Option value="selangor">Selangor</Select.Option>
                <Select.Option value="terengganu">Terengganu</Select.Option>
              </Select>
            </Form.Item>

            <p style={{ color: '#757575' }}>How did you hear about us?</p>
            <Form.Item>
              <Select
                style={{ textAlign: 'left' }}
                className="register-border-input"
                name="refer"
                placeholder="Select Option"
                value={formData.refer}
                onChange={value => handleChange2(value, 'refer')}
              >
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value="Colleague">Colleague</Select.Option>
                <Select.Option value="Website">Website</Select.Option>
                <Select.Option value="Seminar">
                  During IMFeD Seminar
                </Select.Option>
                <Select.Option value="SalesRep">Sales Rep</Select.Option>
                <Select.Option value="others">Others</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Button
                  loading={isLoading}
                  onClick={handleSubmit}
                  style={{
                    margin: 'auto',
                    justifyContent: 'center',
                    width: '40%',
                    background: 'transparent',
                    color: '#6164c1',
                    border: '2px solid #6164c1'
                  }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}
export default ForceUpdateModal
