import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player/lazy'

const ModuleVideo = ({ setSelectedSidebarItem }) => {
  const [VideoData, setVideoData] = useState([])

  const location = useLocation()

  const moduleId = location.state
  const userId = useSelector(state => state.user.user?.data.id)

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.post('/api/module/getVideo', {
          id: location.state
        })

        const data = response.data

        if (response.status === 200) {
          setVideoData(data.data)
        } else {
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    fetchVideoData()
  }, [location.state])

  const updateStatus = async () => {
    try {
      const response = await axios.post('/api/module/updateModuleStatus', {
        userId: userId,
        moduleId: moduleId
      })

      if (response.status === 200) {
        setSelectedSidebarItem('Assessment')
        console.log('Status updated successfully')
      } else {
        console.error('Failed to update status')
      }
    } catch (error) {
      console.error('Error updating status:', error)
    }
  }

  const youtubeVideoLink = `${VideoData.youtubeLink}`

  if (!youtubeVideoLink) {
    return <div>No video link available</div>
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="video-page-container">
        <div className="video-page-wrapper">
          <ReactPlayer
            controls={true}
            url={youtubeVideoLink}
            width="100%"
            height={'100%'}
            onEnded={updateStatus}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={e => e.preventDefault()}
          />
        </div>
      </div>
    </div>
  )
}

export default ModuleVideo
