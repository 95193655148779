import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import parentsImage from '../assets/parentsImage.jpg'
import parents2 from '../assets/home1.png'
import parents3 from '../assets/home2.png'
import '../css/home.css'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

const Home = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  // Check if auth token exists in local storage on component mount
  useEffect(() => {
    const authToken = localStorage.getItem('imfed-token')
    setIsLoggedIn(!!authToken) // Set isLoggedIn to true if authToken exists
  }, [])

  return (
    <div>
      <Header />
      <Title text="Welcome To IMFeD For Growth" />

      <div
        style={{
          textAlign: 'center'
        }}
        className="home-video-container"
      >
        <iframe
          className="home-video"
          width="100%"
          src="https://www.youtube.com/embed/RGgHv99VIss"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="container">
        <div className="mx-3 two-column-container  left-right-10percent-margin mt-5">
          <div>
            <div className="">
              <img style={{ width: '100%' }} src={parents2} alt="About Us" />
            </div>
            <div
              className="purple-background text-white py-4"
              style={{
                textAlign: 'left',
                paddingLeft: '20px',
                borderRadius: '0px 0px 10px 10px'
              }}
            >
              <p
                style={{ color: 'white', fontWeight: '600', fontSize: '20px' }}
              >
                Guidance for Parents
              </p>
              <ul>
                <li>Should you be concerned over your child's growth?</li>
                <li>How well is your child growing?</li>
                <li>Want to help your child grow optimally?</li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <button
                  style={{
                    width: '50%',
                    background: 'transparent',
                    color: 'white',
                    border: '2px solid white',
                    margin: 'auto',
                    marginTop: '30px',
                    marginBottom: '20px'
                  }}
                  onClick={() => navigate('/parents')}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img style={{ width: '100%' }} src={parents3} alt="About Us" />
            </div>
            <div
              className="purple-background text-white py-4"
              style={{
                textAlign: 'left',
                paddingLeft: '20px',
                borderRadius: '0px 0px 10px 10px'
              }}
            >
              <p
                style={{ color: 'white', fontWeight: '600', fontSize: '20px' }}
              >
                Exclusively for Healthcare Professional
              </p>
              <ul>
                <li>Paediatric Growth & Nutrition Online CPD Course</li>
                <li>Past lectures by experts</li>
                <li>Materials for your practice</li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <button
                  style={{
                    width: '50%',
                    background: 'transparent',
                    color: 'white',
                    border: '2px solid white',
                    margin: 'auto',
                    marginTop: '30px',
                    marginBottom: '20px'
                  }}
                  onClick={() =>
                    navigate(isLoggedIn ? '/healthcare' : '/register')
                  }
                >
                  {isLoggedIn ? 'Proceed' : 'Sign Up/Login'}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mx-3 two-column-container purple-background left-right-10percent-margin"
          style={{ alignItems: 'center', borderRadius: '10px' }}
        >
          <div className=" text-white">
            <p style={{ textAlign: 'center' }}>
              Not sure where to find a doctor to help your child?
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '700'
              }}
            >
              You may speak to an IMFeD practitioner!
            </p>
            <button
              style={{
                background: 'transparent',
                color: 'white',
                border: '2px solid white',
                margin: 'auto',
                display: 'block'
              }}
              onClick={() => navigate('/clinic')}
            >
              Find an IMFeD Practitioner
            </button>
          </div>
          <div>
            <img
              className="w-100"
              style={{ borderRadius: '0px 10px 10px 0px' }}
              src={parentsImage}
              alt="About Us"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
