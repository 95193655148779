import Header from '../components/Header';
import Footer from '../components/Footer';
import Title from '../components/Title';

const Privacy = () => {
    return (
        <div>
            <Header />

            <div style={{ paddingLeft: '25%', paddingRight: '25%' }}>
                <p>Home » Privacy Policy</p>
                <h1>Privacy Policy</h1>
                <h1>PRIVACY POLICY</h1>
                <p>Last updated: September 24, 2020</p>

                <p>IMFeD For Growth is collectively owned by Malaysia Paediatric Association (MPA) and Abbott Malaysia. This programme is funded by corporate sponsors and operated by Ultra Works as programme secretariat. MPA, Abbott Malaysia and Ultra Works will henceforth be referred to as “us”, “we’ or “our”.</p>

                <p>This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>

                <p> We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

                <h1>Collection of Information</h1>
                <p>Personal Information: While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name.</p>

                <p>Aggregate and Anonymous Data: This data does not identify you specifically, including data collected automatically through the operation of this Site. We may collect site usage data through the use of various technologies and during some visits, we may use software tools to measure and collect session information, including page response times, time spent on certain pages and so forth.</p>

                <h1>Use of Your Data</h1>
                <p>Personal Information: We will use this data for the purposes for which it was provided and nothing else. In accordance with the Personal Data Protection Act 2010 of Malaysia (the Act), you have the right to contact us at matters@imfed.my and request that:</p>

                <ul>
                    <li>Your Personal Information not be used to contact you directly</li>
                    <li>Previous consent to use your Personal Information be revoked</li>
                    <li>Your Personal Information be expunged from our database.</li>
                </ul>

                <p>Aggregate and Anonymous Data: We use this data in order to better understand and serve the users of the Site. We may conduct research on out visitor demographics, interests and behaviour based on Personal Information we gather. Although this aggregated data may be based in part of Personal Information, it does not identify you personally. We may share this type of data with a variety of third parties, including out programme partners for non-commercial (academic) purposes.</p>

                <h1>Disclosure of Personal Data</h1>
                <p>Disclosure of Programme Partners: We reserve the right to share Personal Information with our programme partners.</p>

                <p>Disclosure to Third Parties: We hire other companies to perform functions such as administering this Site. However, the third party will only be provided with the particular Personal Information it needs to perform its function, and is required to protect the Personal Information.</p>

                <p>Disclosure to Others: In addition, we may disclose Personal Information without providing you with a choice under the following limited circumstances to: (1) protect our legal rights, privacy or safety, or partners and affiliates; (2) protect the safety and security of visitors to our Site or other properties; (3) protect against fraud or other illegal activity or for risk management purposes; (4) respond to inquires or requests from public authorities; (5) permit us to pursue available remedies or limit the damages that we may sustain; (6) enforce out Terms & Conditions; or (7) comply with the law or legal process.</p>

                <h1>Children</h1>
                <p>Children under the age of 13 years of age are expressly prohibited from submitting or posting any Personal Information on this Site.</p>

                <h1>Changes to This Privacy Policy</h1>
                <p>From time to time, we may revise this Privacy Policy. Any changes to this policy will become effective upon posting of the revised Privacy Policy on the Site. By continuing to use the Site following such changes, you will be deemed to have agreed to such changes. If you do not agree with the terms of this Privacy Policy, as it may be amended from time to time, in whole or in part, please do not continue using this Site.</p>
            </div>

            <Footer />
        </div>
    );
};

export default Privacy;
