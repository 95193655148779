import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import ModuleVideo from '../components/ModuleVideo'
import ModuleAssessment from '../components/ModuleAssessment'
import ModuleCertificate from '../components/ModuleCertificate'
import ModulePreAssessment from '../components/ModulePreAssessment'
import { useSelector } from 'react-redux'

const ModuleContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedSidebarItem, setSelectedSidebarItem] = useState(
    'PreAssessment'
  )
  const [module, setModule] = useState('')
  const [certificate, setCertificate] = useState('')
  const [video, setVideo] = useState('')
  const [preAssessment, setPreAssessment] = useState('')
  const userId = useSelector(state => state.user.user?.data.id)

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await axios.post('/api/module/getVideo', {
          id: location.state
        })

        const data = response.data

        if (response.status === 200) {
          setModule(data.data)
        } else {
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state) {
      fetchModuleData()
    }
  }, [location.state])

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.post('/api/module/getModuleVideo', {
          ModuleId: location.state,
          UserId: userId
        })

        const data = response.data

        if (response.status === 200) {
          setVideo(data.video)
        } else {
          setVideo('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state && userId) {
      fetchVideoData()
    }
  }, [location.state, userId])

  useEffect(() => {
    const fetchPreAssessmentStatus = async () => {
      try {
        const response = await axios.post(
          '/api/module/getPreAssessmentStatus',
          {
            ModuleId: location.state,
            UserId: userId
          }
        )

        const data = response.data
        if (response.status === 200) {
          setPreAssessment(data.preAssessment)
        } else {
          setPreAssessment('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state && userId) {
      fetchPreAssessmentStatus()
    }
  }, [location.state, userId])

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        const response = await axios.post('/api/module/getCertificate', {
          ModuleId: location.state,
          UserId: userId
        })

        const data = response.data
        // console.log('data', data)
        if (data.certificate) {
          setCertificate(data.certificate)
        } else {
          setCertificate('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        if (error.response?.data.error === 'Invalid Credentials') {
          navigate('/login')
        } else {
          message.error(error.response?.data.error)
        }
      }
    }

    if (location.state && userId && preAssessment && video) {
      fetchCertificateData()
    }
  }, [preAssessment, video, location.state, userId, navigate])
  // console.log('video', video)

  useEffect(() => {
    if (certificate === true) {
      setSelectedSidebarItem('Certificate')
    } else {
      if (preAssessment === true && video === false) {
        setSelectedSidebarItem('Video')
      } else if (preAssessment === true && video === true) {
        setSelectedSidebarItem('Assessment')
      } else if (
        preAssessment === false &&
        video === false &&
        certificate === false
      ) {
        setSelectedSidebarItem('PreAssessment')
      }
    }
  }, [preAssessment, video, certificate])

  // console.log('video', video)

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <Title text="E-Learning" />
      <div className="video-container">
        <div className="e-learning-container">
          {selectedSidebarItem !== 'Certificate' ? (
            <div className="button-bar">
              <button
                style={{ width: 'auto' }}
                onClick={() => navigate('/module')}
                htmlType="submit"
              >
                Previous
              </button>
              <h4 style={{ margin: 'auto' }}>{module.title}</h4>
            </div>
          ) : (
            <div className="button-bar">
              <button
                style={{ width: 'auto', display: 'none' }}
                onClick={() => navigate('/module')}
                htmlType="submit"
              >
                Previous
              </button>
              <h2 style={{ margin: 'auto' }}>Certificate</h2>
            </div>
          )}
          <div className="content">
            <div className="sidebar">
              <ul className="no-bullets">
                {preAssessment ? (
                  <div>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '10px',
                        fontWeight:
                          selectedSidebarItem === 'PreAssessment'
                            ? 'bold'
                            : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'PreAssessment'
                            ? 'none'
                            : 'none'
                      }}
                    >
                      Pre Assessment {module.order}
                    </li>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontWeight:
                          selectedSidebarItem === 'Video' ? 'bold' : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'Video' ? 'none' : 'none'
                      }}
                      onClick={() => setSelectedSidebarItem('Video')}
                    >
                      Video
                    </li>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontWeight:
                          selectedSidebarItem === 'Assessment'
                            ? 'bold'
                            : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'Assessment' ? 'none' : 'none'
                      }}
                      onClick={() =>
                        video ? setSelectedSidebarItem('Assessment') : null
                      }
                    >
                      Assessment {module.order}
                    </li>
                  </div>
                ) : (
                  <div>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontWeight:
                          selectedSidebarItem === 'PreAssessment'
                            ? 'bold'
                            : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'PreAssessment'
                            ? 'none'
                            : 'none'
                      }}
                      onClick={() => setSelectedSidebarItem('PreAssessment')}
                    >
                      Pre Assessment {module.order}
                    </li>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontWeight:
                          selectedSidebarItem === 'Video' ? 'bold' : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'Video' ? 'none' : 'none'
                      }}
                    >
                      Video
                    </li>
                    <li
                      style={{
                        cursor: 'pointer',
                        marginTop: '20px',
                        fontWeight:
                          selectedSidebarItem === 'Assessment'
                            ? 'bold'
                            : 'normal',
                        borderLeft:
                          selectedSidebarItem === 'Assessment' ? 'none' : 'none'
                      }}
                    >
                      Assessment {module.order}
                    </li>
                  </div>
                )}
                {certificate ? (
                  <li
                    style={{
                      cursor: 'pointer',
                      marginTop: '20px',
                      fontWeight:
                        selectedSidebarItem === 'Certificate'
                          ? 'bold'
                          : 'normal',
                      borderLeft:
                        selectedSidebarItem === 'Certificate' ? 'none' : 'none'
                    }}
                    onClick={() => setSelectedSidebarItem('Certificate')}
                  >
                    Certificate
                  </li>
                ) : (
                  <li
                    style={{
                      cursor: 'pointer',
                      marginTop: '20px',
                      fontWeight:
                        selectedSidebarItem === 'Certificate'
                          ? 'bold'
                          : 'normal',
                      borderLeft:
                        selectedSidebarItem === 'Certificate' ? 'none' : 'none'
                    }}
                  >
                    Certificate
                  </li>
                )}

                <li
                  style={{ cursor: 'pointer', marginTop: '20px' }}
                  onClick={() =>
                    navigate('/askExpert', { state: location.state })
                  }
                >
                  Ask the Expert
                </li>
              </ul>
            </div>
            <div className="">
              {(() => {
                switch (selectedSidebarItem) {
                  case 'Video':
                    return (
                      <ModuleVideo
                        setSelectedSidebarItem={setSelectedSidebarItem}
                      />
                    )
                  case 'Assessment':
                    return <ModuleAssessment navigate={navigate} />
                  case 'Certificate':
                    return <ModuleCertificate />
                  case 'PreAssessment':
                    return <ModulePreAssessment />
                  default:
                    return null // Render nothing if no match
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ModuleContent
