import Header from '../../components/Header'
import Title from '../../components/Title'
import parents1 from '../../assets/parents1.png'
import parents2 from '../../assets/parents2.png'
import parents3 from '../../assets/parents3.png'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'

const Article3 = () => {
  const navigate = useNavigate()

  const blueBorderStyle = {
    border: '1px solid #7a7a7a',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '20px'
  }

  const paragraphStyle = { color: '#7a7a7a' }
  const paragraphStyle2 = { color: '#712e86', fontWeight: '600', margin: 0 }
  return (
    <div>
      <Header />
      <Title text="Want to help your child grow optimally?" />
      <div className="article-container">
        <img className="article-image" src={parents3} />

        <p style={paragraphStyle}>
          The best support you can get is always from your child's doctor!
        </p>

        <p style={paragraphStyle}>
          As parents, allocating time to visit your child’s doctor while
          juggling between work and other house chores may be demanding. Some
          may also feel intimidated to visit the doctor.
        </p>

        <p style={paragraphStyle}>
          However, do not worry, a little preparation may help.
        </p>

        <div style={blueBorderStyle}>
          <h3 style={paragraphStyle2}>
            <strong>Before Your Doctor’s Visit</strong>
          </h3>
          <ol>
            <li style={paragraphStyle}>
              Jot down notes on issues you would like to address before visiting
              your child’s doctor.
            </li>
            <li style={paragraphStyle}>
              Record any symptoms, behavior, or eating patterns that your child
              may be having.
            </li>
            <li style={paragraphStyle}>
              Bring a list of your child’s medication (if any).
            </li>
            <li style={paragraphStyle}>
              Bring your child’s immunization record.
            </li>
          </ol>
        </div>

        <div style={blueBorderStyle}>
          <h3 style={paragraphStyle2}>
            <strong>During Your Doctor’s Visit</strong>
          </h3>

          <p style={paragraphStyle}>
            Here are some questions you may use to discuss with your child’s
            doctor:
          </p>

          <ul>
            <li style={paragraphStyle}>
              <strong>Growth measurements</strong>
              <ul>
                <li style={paragraphStyle}>What do the percentiles mean?</li>
                <li style={paragraphStyle}>
                  Have my child’s measurements changed since the last visit?
                </li>
                <li style={paragraphStyle}>
                  Is my child’s measurement suggesting a growth issue?
                </li>
                <li style={paragraphStyle}>
                  What else can I do to help my child grow?
                </li>
                <li style={paragraphStyle}>
                  What is my child’s mid-parental height, and is my child on
                  track to reach it?
                </li>
              </ul>
            </li>

            <li style={paragraphStyle}>
              <strong>Feeding, nutrition, and behavior</strong>
              <ul>
                <li style={paragraphStyle}>Is my child eating too little?</li>
                <li style={paragraphStyle}>
                  What kind of foods can my child eat?
                </li>
                <li style={paragraphStyle}>
                  How should I introduce new food to my child?
                </li>
                <li style={paragraphStyle}>
                  What can I do if my child is eating very little?
                </li>
                <li style={paragraphStyle}>
                  How much sleep is adequate for my child?
                </li>
                <li style={paragraphStyle}>
                  How can I encourage physical activities for my child?
                </li>
                <li style={paragraphStyle}>
                  Do I need to see a specialist or a dietitian or a
                  psychologist?
                </li>
                <li style={paragraphStyle}>What are the next steps?</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="other-article"
        style={{ fontSize: '25px', color: '#712e86' }}
      >
        <button onClick={() => navigate(-1)} className="button">
          Back
        </button>
      </div>

      <div
        className="other-article"
        style={{ fontSize: '25px', color: '#712e86' }}
      >
        Related Article
      </div>
      <div className="other-article">
        <div className="small-parents-container">
          <div className="small-parents-image">
            <img src={parents1} alt="About Us" />
          </div>
          <div className="small-parents-text">
            <p
              onClick={() => navigate('/article2')}
              style={{
                color: '#712e88',
                fontWeight: '600',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              Should you be concerned over your child’s growth?
            </p>
          </div>
        </div>
        <div className="small-parents-container">
          <div className="small-parents-image">
            <img src={parents2} alt="About Us" />
          </div>
          <div className="small-parents-text">
            <p
              onClick={() => navigate('/article3')}
              style={{
                color: '#712e88',
                fontWeight: '600',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              So, how well is your child growing?
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Article3
