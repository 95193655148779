import React, { useState, useEffect } from 'react'
import headerLogo from '../assets/headerLogo.png'
import { useNavigate, Link } from 'react-router-dom'
import LoginModal from './modal/login'

function Navbar() {
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoginModalOpen, setLoginModalOpen] = useState(false)

  // Check if auth token exists in local storage on component mount
  useEffect(() => {
    const authToken = localStorage.getItem('imfed-token')
    const loggedIn = localStorage.getItem('imfed-user')
    if (loggedIn && authToken) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const checkUrl = urlInput => {
    const url = window.location.href
    if (url.includes(urlInput)) {
      return true
    }
  }

  return (
    <nav
      className={`${menuOpen ? 'mobile-menu-open' : 'bg-white'}`}
      style={{ background: '#fff' }}
    >
      <div className="centered-content mb-3">
        <div className="logo" style={{ marginLeft: '5%' }}>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/home')}
            src={headerLogo}
            alt="Your Logo"
          />
        </div>
        <div className="right-section bg-white">
          <div className="top-menu mt-2">
            <Link to={isLoggedIn ? '/profile' : '/register'}>
              {isLoggedIn ? 'My IMFeD Account' : 'Join IMFed Now'}
            </Link>
            <Link
              to={isLoggedIn ? '/login' : ''}
              onClick={() => {
                if (isLoggedIn) {
                  navigate('/login')
                  localStorage.clear()
                } else {
                  setLoginModalOpen(true)
                }
              }}
            >
              {isLoggedIn ? 'Logout' : 'Login'}
            </Link>
          </div>
          <div className={`menu ${menuOpen ? 'mobile-menu' : ''}`}>
            <Link
              className={checkUrl('/about') ? 'active-navbar' : ''}
              to="/about"
            >
              About
            </Link>
            <Link
              to="/expert"
              className={checkUrl('/expert') ? 'active-navbar' : ''}
            >
              Expert Panel
            </Link>
            <Link
              to="/parents"
              className={checkUrl('/parents') ? 'active-navbar' : ''}
            >
              Parents
            </Link>
            <Link
              to="/healthcare"
              className={checkUrl('/healthcare') ? 'active-navbar' : ''}
            >
              Healthcare Professionals
            </Link>
            <Link
              to="/clinic"
              className={checkUrl('/clinic') ? 'active-navbar' : ''}
            >
              Clinic Directory
            </Link>
            <Link
              to="/contact"
              className={checkUrl('/contact') ? 'active-navbar' : ''}
            >
              Contact Us
            </Link>
          </div>

          {menuOpen ? (
            <span>
              <a
                style={{ cursor: 'pointer' }}
                onClick={
                  isLoggedIn
                    ? () => {
                        navigate('/login')
                        localStorage.clear()
                      }
                    : () => setLoginModalOpen(true)
                }
              >
                {isLoggedIn ? 'Logout' : 'Login'}
              </a>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(isLoggedIn ? '/profile' : '/register')}
              >
                {isLoggedIn ? 'My IMFeD Account' : 'Join IMFed Now'}
              </a>
            </span>
          ) : null}
        </div>

        <div className="hamburger-menu me-3" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </nav>
  )
}

export default Navbar
