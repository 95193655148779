import React from 'react'
import { useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate()
  return (
    <footer className="footer" style={{ marginTop: '20px' }}>
      <div className="footer-left">Copyright © {new Date().getFullYear()} </div>
      <div className="footer-right">
        <a onClick={() => navigate('/disclaimer')}>Disclaimer</a>
        <a onClick={() => navigate('/privacy')}>Privacy Policy</a>
        <a onClick={() => navigate('/tnc')}>Terms & Conditions</a>
      </div>
    </footer>
  )
}

export default Footer
