import Header from '../components/Header'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const MaterialsInformation = () => {
  const [materialsData, setMaterialsData] = useState([])
  const location = useLocation()

  useEffect(() => {
    const fetchMaterialsInfo = async () => {
      try {
        const response = await axios.post('/api/module/materialsInformation', {
          id: location.state.id
        })

        const data = response.data

        if (response.status === 200) {
          setMaterialsData(data.data)
        } else {
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    fetchMaterialsInfo()
  }, [location.state])

  return (
    <div>
      <Header />
      <div
        style={{ paddingRight: '25%', paddingLeft: '25%', marginTop: '40px' }}
      >
        <iframe
          width="560"
          height="315"
          src={materialsData.youtubeLink}
          frameBorder="0"
          allowFullScreen
          title="YouTube Video"
        ></iframe>
        <p style={{ color: '#712E88', fontSize: '20px', fontWeight: '600' }}>
          {materialsData.Title}
        </p>
        {materialsData.speakerBold ? (
          <p style={{ fontWeight: 'bold' }}>{materialsData.speakerBold}</p>
        ) : null}
        {materialsData.textDescription ? (
          <p>{materialsData.textDescription}</p>
        ) : null}
        {materialsData.speaker1 ? (
          <div>
            <ul>
              <li>{materialsData.speaker1}</li>
              <li>{materialsData.speaker2}</li>
              <li>{materialsData.speaker3}</li>
              <li>{materialsData.speaker4}</li>
            </ul>
          </div>
        ) : null}
        {materialsData.moderator ? (
          <div>
            <p style={{ fontWeight: '600' }}>Moderator:</p>
            <p>{materialsData.moderator}</p>
          </div>
        ) : null}
        {materialsData.panellist1 ? (
          <div>
            <li>{materialsData.panellist1}</li>
            <li>{materialsData.panellist2}</li>
            <li>{materialsData.panellist3}</li>
          </div>
        ) : null}

        <img
          style={{ width: '100%', marginTop: '10px' }}
          src={materialsData.imageURL}
          alt="img"
        />
      </div>
    </div>
  )
}

export default MaterialsInformation
