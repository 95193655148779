import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { message } from 'antd';

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const datems = params.get('datems');

    if (!datems) {
      message.error('Link invalid');
      return;
    }

    const thisDate = new Date(parseInt(datems));
    const today = new Date();

    // Compare the dates directly
    if (
      thisDate.getDate() !== today.getDate() ||
      thisDate.getMonth() !== today.getMonth() ||
      thisDate.getFullYear() !== today.getFullYear()
    ) {
      message.error('Link expired');
      return;
    }

    const userId = params.get('userId');
    setUserId(userId);

    if (userId && datems) {
      verifyUser(userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const verifyUser = async (userId) => {
    try {
      const response = await fetch('/api/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      if (response.ok) {
        message.success('Account verified!');
        setTimeout(() => {
          navigate('/healthcare');
        }, 500);
      } else {
        console.error('Error verifying user:', response.statusText);
        message.error('error');
      }
    } catch (error) {
      console.error('Error verifying user:', error.message);
    }
  };

  return (
    <div>
      <Header />
      <h1 style={{ textAlign: 'center' }}>Thank you</h1>
    </div>
  );
};

export default Verify;
