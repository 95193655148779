import Header from "../../components/Header"
import Title from "../../components/Title";
import parents1 from "../../assets/parents1.png";
import parents2 from "../../assets/parents2.png";
import parents3 from "../../assets/parents3.png";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

const Article1 = () => {
    const navigate = useNavigate();
    return (
        <div>

            <Header />
            <Title text="Perlukah Anda Bimbang Akan Tumbesaran Anak Anda?" />
            <div className="article-container">
                <img className="article-image" src={parents1} />

                <p style={{color: '#7a7a7a'}}>Sebagai ibu bapa, anda berusaha untuk memastikan segalanya adalah terbaik untuk anak anda demi menjamin masa depan yang cerah bagi mereka. Namun, walaupun anda telah berusaha, kadangkala kepentingan tumbesaran optimum anak anda terabai kerana anda sukar untuk menyeimbangkan kegiatan harian.</p>

                <p style={{color: '#7a7a7a'}}>Tumbesaran adalah petunjuk utama kesihatan dan perkembangan keseluruhan anak anda.<sup>1</sup>.</p>

                <p style={{color: '#7a7a7a'}}>Masalah tumbesaran disebabkan beberapa faktor termasuklah pemakanan yang tidak seimbang, masalah pemberian makanan, kerap jatuh sakit dan penyakit kronik sedia ada.<sup>2</sup>.</p>

                <p style={{color: '#7a7a7a'}}>Masalah tumbesaran boleh mengakibatkan:<sup>3</sup>.</p>

                <ul>
                    <li style={{color: '#7a7a7a'}}>Risiko jangkitan yang lebih tinggi</li>
                    <li style={{color: '#7a7a7a'}}>Perkembangan otak dan kemahiran sosial yang lemah</li>
                    <li style={{color: '#7a7a7a'}}>Pencapaian sekolah yang lemah</li>
                    <li style={{color: '#7a7a7a'}}>Ketinggian dewasa yang lebih rendah</li>
                    <li style={{color: '#7a7a7a'}}>Rendah pendapatan dan produktiviti setelah dewasa</li>
                    <li style={{color: '#7a7a7a'}}>Peningkatan risiko penyakit kronik setelah dewasa</li>
                </ul>

                <p style={{color: '#7a7a7a'}}>Tumbesaran dan perkembangan kanak-kanak amat penting semasa 5 tahun pertama kehidupan mereka<sup>4</sup>. Pada usia 5 tahun, anak anda perlu mencapai 60% ketinggian dewasa mereka<sup>5</sup>. Oleh itu, pengesanan awal bagi sebarang masalah tumbesaran dan rawatan awal dapat menjamin masa depan yang lebih cerah.</p>

                <h2>Rujukan</h2>
                <ol>
                    <li style={{color: '#7a7a7a'}}>Millward, D. J. (2017, June 1). Nutrition, infection and stunting: The roles of deficiencies of individual nutrients and foods, and of inflammation, as determinants of reduced linear growth of children. Nutrition Research Reviews. Cambridge University Press. <a
                        href="https://doi.org/10.1017/S0954422416000238" target="_blank">https://doi.org/10.1017/S0954422416000238</a></li>
                    <li style={{color: '#7a7a7a'}}>World Health Organisation. (2014). Global nutrition targets 2025: Stunting policy brief. Geneva: Geneva: World Health Organisation.</li>
                    <li style={{color: '#7a7a7a'}}>de Onis, M., & Branca, F. (2016). Childhood stunting: A global perspective. Maternal and Child Nutrition, 12, 12–26. <a
                        href="https://doi.org/10.1111/mcn.12231" target="_blank">https://doi.org/10.1111/mcn.12231</a></li>
                    <li style={{color: '#7a7a7a'}}>Raising Children Network (RCN). (2020). Child development: the first five years. Retrieved June 16, 2021, from <a
                        href="https://raisingchildren.net.au/toddlers/development/understanding-development/development-first-five-years" target="_blank">https://raisingchildren.net.au/toddlers/development/understanding-development/development-first-five-years</a></li>
                    <li style={{color: '#7a7a7a'}}>National Center for Chronic Disease Prevention and Health Promotion. 2 to 20 Years: Boys Stature Weight-for-Age Percentiles.; 2000. <a
                        href="https://www.cdc.gov/growthcharts/" target="_blank">https://www.cdc.gov/growthcharts/</a></li>
                </ol>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                <button onClick={() => navigate(-1)} className="button">Kembali</button>
            </div>

            <div className="other-article" style={{ fontSize: '25px', color: '#712e86' }}>
                 Artikel berkaitan
            </div>
            <div className="other-article">
            <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents2} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article2")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                            Adakah Anda Mahu Membantu Anak Anda Mmembesar Dengan Baik?
                        </p>
                    </div>
                </div>
                <div className="small-parents-container">
                    <div className="small-parents-image">
                        <img src={parents3} alt="About Us" />
                    </div>
                    <div className="small-parents-text">
                        <p onClick={() => navigate("/bm/article3")} style={{ color: "#712e88", fontWeight: '600', fontSize: '20px', cursor: 'pointer' }}>
                           Jadi, Adakah Anak Anda Membesar Dengan Baik?
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Article1;