import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("imfed-token") || null,
  user: null,
  isAuthenticated: false,
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    },
    saveToken: (state, action) => {
      localStorage.setItem("imfed-token", action.payload.token);
      return {
        ...state,
        token: action.payload.token
      };
    },
    logoutUser: () => {
      localStorage.removeItem('imfed-token');
    },

  },
  extraReducers: (builder) => { },
});

export const { saveToken, saveUser, logoutUser } =
  userSlice.actions;
export default userSlice.reducer;
